import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button,CardFooter,
CardText,
FormGroup,
Form,
Input,
Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select , { components } from 'react-select';
import { Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import countryimg from '../../assets/img/country.png';
import abstractimg from '../../assets/img/abstract.png';
import flagimg from '../../assets/img/flag.png';
import dislikeimg from '../../assets/img/dislike.png';
import Trash from '../../assets/img/trash.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {GET,POST,PUT,DELETE} from '../../Services';
import dataHandler from '../../dataHandler';
import API from '../../Service/Api';


function Abstractorpop(props) { 

  console.log("pppp",props.data);

  const [fieldError, setFieldError] = React.useState({abstractor_name:'',lookup_code:'',state_id:'',county_id:'',client_exception:'',price:'',copy_price:'',sla:'',preferred_provider:'',notes:'',underwriter:'',titles:''});

  const [data, setData] = useState({_id:null,abstractor_name:null,lookup_code:null,state_id:null,county_id:[],client_exception:[''],price:0,copy_price:0,sla:null,preferred_provider:null,notes:null,underwriter:null,underwriterdefault:{},titles:null,titlesdefault:{},statedefault:{},countydefault:[]});

  const toastId = useRef(null);

  useEffect(()=>{
    if(props.data != null){
      setData(prevState => ({ ...prevState, ...props.data}))
      getCounty(props.data.state_id);
    }
  },[])


  const [countyOptions, setCountyOptions] = React.useState([]);

  const Addstateemty = [
  {
    dataField: "addstate",
    text: "",
     // sort: true,
    headerClasses: 'th-first1',
  },

  {
    dataField: "Action",
    text: "",
    headerClasses: 'th-last1',
    formatter: (cellContent, row) => (
      <div className="">
      <Button color="success" style={{fontSize:"12px",padding:"1px 12px",margin:"0px 5px"}} >Edit</Button>

      </div>
      )
  }
  ];

  const toggle = () =>{
    props.setAbstractModal(prevState => ({ ...prevState, show:false }))
  };

  const changeValue = (value,key) =>{
    setData(prevState => ({ ...prevState, [key]:value }))
  };  

  const selectTitle = (value,key) =>{
    setData(prevState => ({ ...prevState, 'titles':value.value,titlesdefault:value}))
  };  

  const selectState = (value) =>{
    setData(prevState => ({ ...prevState, 'state_id':value.value,'statedefault':value,countydefault:[] }))
    getCounty(value.value);
  };  

  const getCounty = async(state)=>{

    var url=API.CountyDropdown+"?search=&skip=&limit=100&sortby=name&sortin=1&page=1&state="+state+"&token="+localStorage.getItem('atoken')
    var response = await GET(url).catch((e) => console.log("Fetch Error", e))

    if (response != null && response.code != null && response.code == 200) {
      var item = response.data.map((itm)=>({'value':itm._id,'label':itm.name})) 
      item.unshift( {'value':'All','label':'All'} );
      setCountyOptions(item)      
    }
  }

  const selectCounty = (e) =>{

    var item = e.map((itm)=>(itm.value)).join(',');
    var result = item.match("All");
    if(result){
      var finalitem = countyOptions.map((itm)=>(itm.value));
      finalitem.shift();
      var coptions=[...countyOptions];
      coptions.shift();
      setData(prevState => ({ ...prevState, 'county_id':finalitem,'countydefault':coptions}))
    }
    else{
      setData(prevState => ({ ...prevState, 'county_id':item,'countydefault':e}))
    } 

  }; 

  const selectUnderwriter = (value) =>{
    setData(prevState => ({ ...prevState, 'underwriter':value.value,'underwriterdefault':value}))
  };  


  const addClientException = () =>{
    var dce = [...data.client_exception];
    dce.push("");
    setData(prevState => ({ ...prevState, 'client_exception':dce }))

  };  

  const clientexception = (value,index) =>{
    var dce = [...data.client_exception];
    dce[index] = value;
    setData(prevState => ({ ...prevState, 'client_exception':dce }))
  };  

  const deleteClientException = (index) =>{
    var dce = [...data.client_exception];
    dce.splice(index,1);
    setData(prevState => ({ ...prevState, 'client_exception':dce }))
  };  



  
  const saveAbstractor = async () =>{
    console.log(data);
    setFieldError(prevState => ({ ...prevState, abstractor_name:'',lookup_code:'',state_id:'',county_id:'',client_exception:'',price:'',copy_price:'',sla:'',preferred_provider:'',notes:'',underwriter:'',titles:''}))

    if(data.abstractor_name == null){
      setFieldError(prevState => ({ ...prevState, abstractor_name:'border border-danger'}))
      return;
    }
    else if(data.state_id == null){
      setFieldError(prevState => ({ ...prevState,  state_id:'border border-danger'}))
      return;
    }
    else if(data.county_id.length == 0){
      setFieldError(prevState => ({ ...prevState,  county_id:'border border-danger'}))
      return;
    }
    else if(data.lookup_code == null){
      setFieldError(prevState => ({ ...prevState,  lookup_code:'border border-danger'}))
      return;
    }
    else if(data.titles == null){
      setFieldError(prevState => ({ ...prevState,  titles:'border border-danger'}))
      return;
    }


    if(data._id == null){

      if(toastId.current === null){
        toastId.current = toast.loading('upload in progress');
      }else{
        toast.update(toastId.current)
      }

      var tempJSON = JSON.stringify(data)


      let tempURL = API.Abstractor+"?token="+localStorage.getItem('atoken')
      var response = await POST(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
      console.log("response ...",response);

      if(response != null)
      {
        toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false,autoClose:5000,})
        toast.done(toastId.current)

        toggle();
        props.getdata()

      }else{
        toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error", autoClose:5000, isLoading:false})
        toast.done(toastId.current)
      }

    }else{

      if(toastId.current === null){
        toastId.current = toast.loading('upload in progress');
      }else{
        toast.update(toastId.current)
      }

      var tempJSON = JSON.stringify(data)

      let tempURL = API.Abstractor+'/'+data._id+"?token="+localStorage.getItem('atoken')
      var response = await PUT(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
      console.log("response ...",response);

      if(response != null)
      {
        toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false,autoClose:5000,})
        toast.done(toastId.current)
        toggle();
        props.getdata()
      }else{
        toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error", autoClose:5000, isLoading:false})
        toast.done(toastId.current)
      }


    }


  };

  return (
    <div>

    <Modal className="addorder" isOpen={props.show} size="xl" centered>
    <ModalHeader  toggle={toggle} style={{ borderBottom: "1px solid #ddd",paddingBottom: "5px"}}>Details</ModalHeader>
    <ModalBody style={{paddingTop:"15px"}}>

    <Row className="py-1">

    <Col md="3">
    <label>Abstractor Name</label>
    <Input className={fieldError.abstractor_name} placeholder="Abstractor Name" type="text" style={{padding:"21px 10px"}} value={data.abstractor_name} onChange={(e)=>{changeValue(e.target.value,'abstractor_name');}}/>
    </Col>

    <Col md="3">
    <label>State</label>
    <Select className={fieldError.state_id} options={props.states} style={{padding:"0 10px"}} onChange={e=>selectState(e)} value={data.statedefault}/>
    </Col> 

    <Col md="6">
    <label>County</label>   
    <Select  className={fieldError.county_id} options={countyOptions} style={{padding:"0 10px"}} isMulti onChange={e=>selectCounty(e)}  value={data.countydefault}  closeMenuOnSelect={false} components={{ MultiValue }}/>
    </Col>

    <Col md="3">
    <label>Lookup Code</label>
    <Input className={fieldError.lookup_code} placeholder="Lookup Code" type="text" style={{padding:"21px 10px"}} value={data.lookup_code} onChange={(e)=>{changeValue(e.target.value,'lookup_code');}}/>
    </Col>

    <Col md="3">
    <label>Underwriter Name</label>
      <Select placeholder="Underwriter" options={props.UnderWriter} style={{ padding: "0 10px" }} onChange={e=>selectUnderwriter(e)} value={data.underwriterdefault && Object.keys(data.underwriterdefault).length === 0? '' : data.underwriterdefault} />
    </Col>

    <Col md="3">
    <label>Insured Titles, Uninsured...</label>
    <Select  className={fieldError.titles} options={props.titles} style={{padding:"0 10px"}} onChange={e=>selectTitle(e)} value={data.titlesdefault}/>
    </Col>

    <Col md="3">
    <label style={{fontSize: "15px"}}>Price</label>
    <Input  placeholder="Abstractor Price" type="text" style={{padding:"21px 10px"}} value={data.price} onChange={(e)=>{changeValue(e.target.value,'price');}}/>
    </Col>

    <Col md="3">
    <label style={{fontSize: "15px"}}>SLA</label>   
    <Input  placeholder="SLA" type="text" style={{padding:"21px 10px"}} value={data.sla} onChange={(e)=>{changeValue(e.target.value,'sla');}}/>
    </Col>

    <Col md="3">
    <label style={{fontSize: "15px"}}>Copy Price</label>   
    <Input  placeholder="Copy Price" type="text" style={{padding:"21px 10px"}} value={data.copy_price} onChange={(e)=>{changeValue(e.target.value,'copy_price');}}/>
    </Col>

    <Col md="3">
    <label style={{fontSize: "15px"}}>Preferred Provider</label>   
    <Input  placeholder="Best Price Preferred Provider" type="text" style={{padding:"21px 10px"}} value={data.preferred_provider} onChange={(e)=>{changeValue(e.target.value,'preferred_provider');}}/>
    </Col>

    </Row>

    <Row >
    <Col md="12">
    <label style={{fontSize: "15px"}}>Notes</label>   
    <Input  placeholder="Notes" type="textarea" style={{padding:"21px 10px"}} value={data.notes} onChange={(e)=>{changeValue(e.target.value,'notes');}}/>
    </Col>
    </Row>

    <Row className="py-1">
    <Col md="6">
    <label>Client Exceptions</label>
    
    { 
      data.client_exception.length >0 && data.client_exception.map((item,index)=>(

        <div style={{display:"flex",justifyContent:"center",alignItems: "center"}}>
        <Input placeholder="Client Exceptions" type="text" style={{padding:"21px 10px"}} value={item} onChange={(e)=>clientexception(e.target.value,index)}/>
        {
          index == (data.client_exception.length-1) &&
          <Button  className="btn-plus"><span style={{fontSize:"30px"}} onClick={addClientException}>+</span></Button>
        }
        <Button  className="btn-plus" ><img src={Trash} style={{height:"25px",objectFit:"contain"}} onClick={()=>deleteClientException(index)}/></Button>
        </div>

        ) )
    } 

    { 
      data.client_exception.length == 0 &&
      <div style={{display:"flex",justifyContent:"center",alignItems: "center"}}>
      <Input placeholder="Client Exceptions" type="text" style={{padding:"21px 10px"}} onChange={(e)=>clientexception(e.target.value,0)}/>
      <Button  className="btn-plus"><span style={{fontSize:"30px"}} onClick={addClientException}>+</span></Button>
      </div>

    }

    </Col>
    </Row>
    <hr/>

    </ModalBody>
    <ModalFooter style={{ justifyContent: "center", padding:"8px"}}>
    <Button color="success" style={{marginRight:"10px",padding:"6px 10px"}} onClick={saveAbstractor} >Submit</Button>
    <Button color="secondary" style={{marginRight:"10px",padding:"6px 10px"}} onClick={toggle}>Close</Button>
    </ModalFooter>
    </Modal>

    </div>
    );
}

export default Abstractorpop;

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",
    fontFamily: "Open Sans",
    fontSize: "11px",
    padding: "3px",
    order: 99
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;

  return (
    <div style={style} title={title}>
    {label}
    </div>
    );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
  .slice(maxToShow)
  .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
    ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
    ) : null;
  };

