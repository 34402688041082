import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button,CardFooter,
CardText,
FormGroup,
Form,
Input,
Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import { Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import countryimg from '../../assets/img/country.png';
import abstractimg from '../../assets/img/abstract.png';
import flagimg from '../../assets/img/flag.png';
import dislikeimg from '../../assets/img/dislike.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {GET,POST,PUT,DELETE} from '../../Services';
import API from '../../Service/Api';


function Countypop(props) {

  const [addCountyModal, setAddCountyModal] = useState({show:false, _id:null,state_id:null,name:null,title:null, state_name:null});
  
  const [fieldError, setFieldError] = React.useState({name:'',state_id:'',title: ''});

  const [data, setData] = React.useState([]);

  const paramRef = useRef({search:'',state:'',sortby:'name',sortin:1,page:1,limit:10,totalSize:0});

  const toastId = useRef(null);

  function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
  }

  useEffect(()=>{
    getdata()
  },[])

  const getdata = async()=>{
    var param = objectToQueryString(paramRef.current);
    var url=API.County+"?"+param+"&token="+localStorage.getItem('atoken')
    var response = await GET(url).catch((e) => console.log("Fetch Error", e))

    if (response != null && response.code != null && response.code == 200) {
      console.log(response);
      paramRef.current.page = response.metadata.page?response.metadata.page:1
      paramRef.current.totalSize = response.metadata.total?response.metadata.total:0
      setData(response.data)
    }
  }

  const Addcountyemty = [
  {
    dataField: "name",
    text: "County",
    sort: true,
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.page=1
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  }, 
  {
    dataField: "states.name",
    text: "State",
    sort: true,
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.page=1
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "title",
    text: "Title",
    sort: true,
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.page=1
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "Action",
    text: "Action",
    formatter: (cellContent, row) => (
      <div className="">
      <Button color="success" style={{fontSize:"12px",padding:"1px 12px",margin:"0px 5px"}} onClick={()=>{setAddCountyModal(prevState => ({ ...prevState, show:true,_id:row._id,state_id:row.state_id,name:row.name,title:row.title, state_name:row.states?.name}))}} >Edit</Button>

        </div>
        )
    }
    ];


const handleTableChange = (e,data) =>{
  paramRef.current.page = data.page
  getdata()
};

const searchCounty = (e) =>{
  paramRef.current.search = e.target.value;
  paramRef.current.page = 1 
  getdata();
};  

const selectState = (e) =>{
  var item = e.map((itm)=>(itm.value) ).join(',');
  paramRef.current.state = item;
  paramRef.current.page = 1 
  getdata();
};  

const changeValue = (value,key) =>{
  setAddCountyModal(prevState => ({ ...prevState, [key]:value }))
};  

const toggle = () =>{
  props.setCountyModal(prevState => ({ ...prevState, show:false }))
};

const toggleAddCounty = () =>{
  setAddCountyModal(prevState => ({ ...prevState, show:false,_id:null,state_id:null,name:null,title:null, state_name:null }))
};


const saveCounty = async () =>{
  console.log(addCountyModal);
  setFieldError(prevState => ({ ...prevState, name:'',state_id:'',title: ''}))

  
  if(addCountyModal.name == null){
    setFieldError(prevState => ({ ...prevState, name:'border border-danger'}))
    return;
  }
  else if(addCountyModal.state_id == null){
    setFieldError(prevState => ({ ...prevState,  state_id:'border border-danger'}))
    return;
  }
  else if(addCountyModal.title == null){
    setFieldError(prevState => ({ ...prevState,  title:'border border-danger'}))
    return;
  }

  if(addCountyModal._id == null){

    if(toastId.current === null){
      toastId.current = toast.loading('upload in progress');
    }else{
      toast.update(toastId.current)
    }

    var tempJSON = JSON.stringify({ name:addCountyModal.name, state_id:addCountyModal.state_id, title:addCountyModal.title  })


    let tempURL = API.County
    var response = await POST(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
    console.log("response ...",response);


    if(response != null)
    {
      toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false,autoClose:5000,})
      toast.done(toastId.current)
      setAddCountyModal(prevState => ({ ...prevState, show:false,_id:null,state_id:null,name:null,title:null, state_name:null}))
      getdata()
    }else{
      toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error", autoClose:5000, isLoading:false})
      toast.done(toastId.current)
    }

  }else{

    if(toastId.current === null){
      toastId.current = toast.loading('upload in progress');
    }else{
      toast.update(toastId.current)
    }

    var tempJSON = JSON.stringify({ name:addCountyModal.name, state_id:addCountyModal.state_id, title:addCountyModal.title  })

    let tempURL = API.County+'/'+addCountyModal._id
    var response = await PUT(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
    console.log("response ...",response);

    if(response != null)
    {
     toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false,autoClose:5000,})
     toast.done(toastId.current)
     setAddCountyModal(prevState => ({ ...prevState, show:false,_id:null,state_id:null,name:null,title:null, state_name:null}))
     getdata()
   }else{
     toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error", autoClose:5000, isLoading:false})
     toast.done(toastId.current)
   }


 }


};


return (
  <div >    

  <Modal className="addorder" isOpen={props.show} toggle={toggle} size="xl" >
  <ModalHeader   style={{ borderBottom: "1px solid #ddd",paddingBottom: "5px"}}>County</ModalHeader>
  <ModalBody  style={{paddingTop:"15px"}}>
  <div className="content">
  <Card style={{border:"0",marginBottom:"0px"}}>

  <CardBody>

  <Row className="py-1">

  <Col md="12" style={{textAlign:"right"}}>          

  <div className='bg-gra-plus modelplus' style={{ padding: "5px 8px",height:"auto"}}>

  <Input placeholder="Search County,State,Titles" type="text" style={{padding:"21px 10px"}}  onChange={searchCounty}/>


  <Select placeholder="Select State" style={{padding:"21px 10px"}} isMulti options={props.states} onChange={selectState}/>

  <Button  className="btn-plus" onClick={()=>{setAddCountyModal(prevState => ({ ...prevState, show:true}))}} >+</Button>
  </div>
{/*
  <div className='bg-gra-plus modelplus' style={{ padding: "5px 8px",height:"50px"}}>
  <Button  className="btn-plus" onClick={()=>{setAddCountyModal(prevState => ({ ...prevState, show:true}))}} >+</Button>
  </div>*/}
  


  <ToolkitProvider
  bootstrap4
  keyField="addstateid"
  data={ data }
  columns={ Addcountyemty }
  search={false}
  >
  { 
    props => (
      <div className='search-select-bar seach-select-bar-abstractor modeltype modalsearchpopup'>

      <BootstrapTable
      classes="Home-table1 abstractortable text-left"
      { ...props.baseProps }
      remote
      pagination={paginationFactory({ page:paramRef.current.page, sizePerPage: paramRef.current.limit,sizePerPageList:[],totalSize:paramRef.current.totalSize,paginationSize:10})}
      striped
      hover
      condensed
       // selectRow={ selectRow }
      style={{marginBottom:"0px"}}
      onTableChange={handleTableChange}
      />

      </div>
      )
  }
  </ToolkitProvider>


  </Col>
  </Row>
  </CardBody>
  </Card>
  </div>
  </ModalBody>
          {/* <ModalFooter style={{ justifyContent: "center", padding:"8px"}}>
          <Button color="success" style={{marginRight:"10px",padding:"6px 10px"}} >Submit</Button>
          <Button color="secondary" style={{marginRight:"10px",padding:"6px 10px"}}>Cancel</Button>
          </ModalFooter> */}
  </Modal>


  <Modal className="addorder" isOpen={addCountyModal.show} size="md" >
  <ModalHeader   style={{ borderBottom: "1px solid #ddd",paddingBottom: "5px"}}>Add County</ModalHeader>
  <ModalBody  style={{paddingTop:"15px"}}>
  <div className="content">
  <Card style={{border:"0",marginBottom:"0px"}}>

  <CardBody>
  <Row>

  <Col md="12">
  <Input placeholder="County Name" className={fieldError.name} type="text" style={{padding:"21px 10px"}} value={addCountyModal.name} onChange={(e)=>{changeValue(e.target.value,'name');}}/>
  </Col>

  <Col md="12" style={{marginTop:"5px"}}>
  <Select placeholder="Select State" className={fieldError.state_id} style={{padding:"21px 10px"}} options={props.states} value={addCountyModal.state_id !=null && addCountyModal.state_id !=''? {value:addCountyModal.state_id,label:addCountyModal.state_name}:''} onChange={(e)=>{changeValue(e.value,'state_id');changeValue(e.label,'state_name');}}/>
  </Col>

  <Col md="12" style={{marginTop:"10px"}}>
  <Select placeholder="Select Title" className={fieldError.title} style={{padding:"21px 10px"}} options={props.titles} value={addCountyModal.title !=null && addCountyModal.title !=''? {value:addCountyModal.title,label:addCountyModal.title}:''} onChange={(e)=>{changeValue(e.value,'title');}}/>
  </Col>

  <Col md="12" className="text-center">
  <Button  color="success" style={{marginTop:"10px",padding:"6px 10px"}} onClick={saveCounty} >Save</Button>
  <Button  color="secondary" style={{marginTop:"10px",padding:"6px 10px"}} onClick={toggleAddCounty}>Cancel</Button>
  </Col>

  </Row>

  </CardBody>
  </Card>
  </div>
  </ModalBody>

  </Modal>


  </div>
  );
}

export default Countypop;