import React,{ useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";

import Login from "pages/login/Login.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

export default function App() {


  const isAuthenticated = () => {
    var tempUserData = localStorage.getItem('userData')
    var userData=JSON.parse(tempUserData)
    if(userData!=null)
    {
      return true;
    }
    else{
      return false;
    }

  }

  const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/login' />
      )} />
    )


  const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      !isAuthenticated()
      ? <Component {...props} />
      : <Redirect to='/Login' />
      )} />
    );

  return (
    <ThemeContextWrapper>
    <BackgroundColorWrapper>
    <BrowserRouter>
    <Switch>
  
    <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} />

    // <UnauthenticatedRoute path="/login" render={(props) => <Login {...props} />} />
    
    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
    // <AuthenticatedRoute path="/admin" component={AdminLayout} />
    <Redirect from="/" to="/admin/orderinformatation" />

    </Switch>
    </BrowserRouter>
    </BackgroundColorWrapper>
    </ThemeContextWrapper>

    );
}
