import React, { Component } from 'react';

export default class Resizer extends Component {
  getStyle() {
    if (this.props.direction === 'column') {
      return {
        width: '100%',
        height: this.props.size,
        background: this.props.color,
        cursor: 'row-resize'
      };
    }

    return {
      width: "3px",
      height: '100%',
      background: "#dae5f2",
      cursor: 'col-resize',
      boxShadow: "#dae5f2 0px 5px 15px",
      position: "relative",
      margin: "0px 3px"
    };
  }

  render() {
    return <div onMouseDown={this.props.onMouseDown} style={this.getStyle()}>
      
    <p className="bar-green"></p>
   </div>;
  }
}
