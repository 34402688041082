import React, { useState, useEffect, useRef,useContext } from 'react'
import { OrderContext } from "../contexts/orderContext";
import Select from 'react-select'
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

import Trash from '../assets/img/trash.png';
import { Icon } from '@react-pdf-viewer/core';
import AWS from "aws-sdk";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {GET,POST,DELETE,POSTFILE} from '../Services';
import API from '../Service/Api';
import Loader from "react-js-loader";


const TransType = [
  {value:"Purchase CD",label:"Purchase CD"},
  {value:"Purchase HUD",label:"Purchase HUD"},
  {value:"Refi CD",label:"Refi CD"},
  {value:"Refi HUD",label:"Refi HUD"},
  {value:"REO CD",label:"REO CD"},
  {value:"REO HUD",label:"REO HUD"},
  {value:"VA Fees",label:"VA Fees"},
  {value:"TX Capital Purchase CD",label:"TX Capital Purchase CD"},
  {value:"TX Capital Purchas  HUD",label:"TX Capital Purchas  HUD"}
  ]


const Marketing = [
  {value:"Selling Agent",label:"Selling Agent"},
  {value:"Listing Agent",label:"Listing Agent"},
  {value:"Lender",label:"Lender"},
  {value:"Mortgage Broker",label:"Mortgage Broker"},
  {value:"Buyer",label:"Buyer"}
  ]

const PurchaseSide = [
  {value:"Buyer",label:"Buyer"},
  {value:"Seller",label:"Seller"},
  {value:"Both",label:"Both"},
  ]

    // S3 Region
const REGION = "us-east-1";
    // S3 Credentials
AWS.config.update({
  accessKeyId: process.env.REACT_APP_BASE_DOMAIN_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_BASE_DOMAIN_SECRETACCESSKEY,
});

const s3 = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_BASE_DOMAIN_S3_BUCKET },
  region: REGION,
});


function Document() {

  const toastId = useRef(null);
   // drag state
  const [dragActive, setDragActive] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const [file1, setFile1] = React.useState(null);
   // ref
  const [confirmyes, setConfirmyes] = React.useState({show:false,message:''});

  const contactRef1 = React.useRef({"filename": "","otherfilename": "","uploadType":"","template": "","transaction": "","marketingSource": "","Abstractor": "","UnderWriter": "","Escrow": "","EscrowAssistant": "","MarketRep": "","contacts":[],"productname":""});


  const inputRef1 = React.useRef(null);
  const inputRef2 = React.useRef(null);
  const inputRef3 = React.useRef(null);
  
  const fileSelected = React.useRef(0);



  const selectInputRef1 = React.useRef(); 
  const selectInputRef2 = React.useRef(); 
  const selectInputRef3 = React.useRef(); 
  const selectInputRef4 = React.useRef(); 
  const selectInputRef5 = React.useRef(); 
  const selectInputRef6 = React.useRef(); 
  const selectInputRef7 = React.useRef(); 
  const selectInputRef8 = React.useRef(); 
  const selectInputRef9 = React.useRef(); 

  const [filename1, setFilename1] = React.useState('');
  const [filename2, setFilename2] = React.useState('');
  const [filename3, setFilename3] = React.useState('');


  const [activeFile, setActiveFile] = React.useState({show:0,fileClass1:'dashborder-card fileInactive',fileClass2:'dashborder-card fileInactive',fileClass3:'dashborder-card fileInactive'});


  const [fieldError, setFieldError] = React.useState({TranType:'',Marketing:'',Abstractor: "",UnderWriter: "",Escrow: "",EscrowAssistant: "",MarketRep: ""});

  const [errorModel, setErroModel] = React.useState({show:false,message:""});
  const [previewModel, setPreviewModel] = React.useState({show:false});

  const [type1,setType1] = useState('')
  const [type2,setType2] = useState('')
  


  const [Abstractor,setAbstractor] = useState([])
  const [UnderWriter,setUnderWriter] = useState([])
  const [Escrow,setEscrow] = useState([])

  useEffect(() => {  
    getDropdowns();
  },[]);

  const getDropdowns = async () => {
    if(Abstractor.length == 0){
      let abstractURL = API.DropdownServies+"?contact=Abstractor&type=json"
      var response1 = await GET(abstractURL).catch((e) => { console.log("Get ERR", e) })
      if(response1 != null && response1.status == 200)
      {
        let result1 = response1.data.map(item=>({value:item.lookupcode,label:item.name}))
        setAbstractor(result1)
      }

      let underwriterURL = API.DropdownServies+"?contact=Underwriter&type=json"
      var response2 = await GET(underwriterURL).catch((e) => { console.log("Get ERR", e) })
      if(response2 != null && response2.status == 200)
      {
        let result1 = response2.data.map(item=>({value:item.lookupcode,label:item.name}))
        setUnderWriter(result1)
      }

      let escrowURL = API.DropdownServies+"?contact=MarketingRep&type=json"
      var response3 = await GET(escrowURL).catch((e) => { console.log("Get ERR", e) })
      if(response3 != null && response3.status == 200)
      {
        let result1 = response3.data.map(item=>({value:item.lookupcode,label:item.name}))
        setEscrow(result1)
      }
      
    } 
  }


   // handle drag events
  const handleDrag = function(e,parts) {
    fileSelected.current=parts
    console.log("handleDrag",e);
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
     setDragActive(true);
   } else if (e.type === "dragleave") {
     setDragActive(false);
   }

 };

   // triggers when file is dropped
 const handleDrop = function(e,parts) {
  console.log("handleDrop",e,parts);
  fileSelected.current=parts
  e.preventDefault();
  e.stopPropagation();
  setDragActive(false);
  if (e.dataTransfer.files && e.dataTransfer.files[0]) {
   handleFile(e.dataTransfer.files[0],parts);
 }
};

   // triggers when file is selected with click
const handleChange = function(e,parts) {
  console.log("handleChange",e,parts);
  e.preventDefault();
  if (e.target.files && e.target.files[0]) {
    handleFile(e.target.files[0],parts);
  }
};


const handleFile = async (file,parts) => {

  console.log("file....",file)

  setLoader(true)


  const fileType = file.type;
  if (fileType === 'application/pdf'){



    console.log("pdf sections")

    setLoader(true)
    let random = Math.floor(Math.random() * 1000);

    var filenam = random+'-'+file.name
    const params = {
      Bucket: process.env.REACT_APP_BASE_DOMAIN_S3_BUCKET,
      Key: filenam,
      Body: file,
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error('Error uploading file:', err);
        setLoader(false)
      } else {

        console.log('File uploaded successfully:', data);
        if(fileSelected.current==1){
          setFilename1(data.Key)
          setType1(1)
        }else if(fileSelected.current==2){
          setFilename2(data.Key)
          setType2(2)
        }else{
          setFilename3(data.Key)
        }
        setLoader(false)
        console.log("parts",fileSelected.current)
      }

    }) .on("httpUploadProgress", (evt) => {
        // File uploading progress
      console.log(
        "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );

    });


  }else{

    console.log("docx sections")

    const formData = new FormData()
    formData.append("file", file)

    let tempURL = API.uploadDocxToPdf
    console.log(tempURL)

  // let tempURL = API.order
    var response = await POSTFILE(tempURL, formData).catch((e) => { console.log("Get ERR", e) })

    console.log("response ...",response);

    if(response != null && response.code == 200)
    {
      if(fileSelected.current==1){
        setFilename1(response.filename)
        setType1(1)
      }else if(fileSelected.current==2){
        setFilename2(response.filename)
        setType2(2)
      }else{
        setFilename3(response.filename)
      }
      setLoader(false)
      console.log("parts",fileSelected.current)

    }else{
      setLoader(false)
    }
  }
}

// const handleFile = async (file,parts) => {

//   console.log("file....")

//   setLoader(true)
//   let random = Math.floor(Math.random() * 1000);

//   var filenam = random+'-'+file.name
//   const params = {
//     Bucket: S3_BUCKET,
//     Key: filenam,
//     Body: file,
//   };

//   s3.upload(params, (err, data) => {
//     if (err) {
//       console.error('Error uploading file:', err);
//       setLoader(false)
//     } else {
//       console.log('File uploaded successfully:', data);
//       if(fileSelected.current==1){
//         setFilename1(data.Key)
//         setType1(1)
//       }else if(fileSelected.current==2){
//         setFilename2(data.Key)
//         setType2(2)
//       }else{
//         setFilename3(data.Key)
//       }

//       setLoader(false)
//       console.log("parts",fileSelected.current)

//     }

//   }) .on("httpUploadProgress", (evt) => {
//         // File uploading progress
//     console.log(
//       "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
//       );

//   });

// }


  // triggers the input when the button is clicked
const onButtonClickref1 = () => {inputRef1.current.click();}; 
const onButtonClickref2 = () => {inputRef2.current.click();}; 
const onButtonClickref3 = () => {inputRef3.current.click();}; 



const openPreview = () => {
  if(contactRef1.current.transaction == ""){
    setFieldError(prevState => ({ ...prevState, TranType:'border border-danger'}))
    return;
  }
  else if(contactRef1.current.Abstractor == ""){
    setFieldError(prevState => ({ ...prevState,  Abstractor:'border border-danger'}))
    return;
  }
  else if(contactRef1.current.UnderWriter == ""){
    setFieldError(prevState => ({ ...prevState,  UnderWriter:'border border-danger'}))
    return;
  }
  else if(contactRef1.current.Escrow == ""){
    setFieldError(prevState => ({ ...prevState,  Escrow:'border border-danger'}))
    return;
  }
  else if(contactRef1.current.EscrowAssistant == ""){
    setFieldError(prevState => ({ ...prevState,  EscrowAssistant:'border border-danger'}))
    return;
  }
  else if(contactRef1.current.marketingSource == ""){
    setFieldError(prevState => ({ ...prevState,  Marketing:'border border-danger'}))
    return;
  }
  else if(contactRef1.current.MarketRep == ""){
    setFieldError(prevState => ({ ...prevState,  MarketRep:'border border-danger'}))
    return;
  }else if(activeFile.show==2){
    if(filename2 ==''){
      setErroModel(prevState => ({ ...prevState,  show:true,message:"Please select refi contract document!"}))
      return;
    }
  }

  else if(activeFile.show==1){
    if(filename1 =='' && filename2 ==''){
      setErroModel(prevState => ({ ...prevState,  show:true,message:"Please select purchase or refi contract documents!"}))
      return;
    }
  }

  setPreviewModel(prevState => ({ ...prevState,  show:true}))
};



const onChangeTransctionType = (value) => {
  value = value.toLowerCase()
  
  if(value.includes('purchase')){
    setActiveFile(prevState => ({ ...prevState,  show:1,fileClass1:'dashborder-card',fileClass2:'dashborder-card',fileClass3:'dashborder-card',}))
  }
  else if(value.includes('refi')){
    setActiveFile(prevState => ({ ...prevState,  show:2,fileClass1:'dashborder-card fileInactive',fileClass2:'dashborder-card',fileClass3:'dashborder-card',}))

  }else{
    setActiveFile(prevState => ({ ...prevState,  show:1,fileClass1:'dashborder-card',fileClass2:'dashborder-card',fileClass3:'dashborder-card',}))
  }

};


const onContactRef = (type,name,lookupcode) => {

  let obj ={
    "type": type,
    "lookupcode": lookupcode,
    "name": name
  }

  var contact = [...contactRef1.current.contacts]
  contact = contact.filter((item)=>item.type != type)
  contact.push(obj)
  contactRef1.current.contacts =contact 

  console.log(contactRef1.current)

  if(type == "Transaction"){
    contactRef1.current.template=name
    contactRef1.current.transaction=name
    setFieldError(prevState => ({ ...prevState,  TranType:''}))
  }else if(type == "MarketSource"){ 
    contactRef1.current.marketingSource=name
    setFieldError(prevState => ({ ...prevState,  Marketing:''}))
  }else if(type == "Abstractor"){ 
    contactRef1.current.Abstractor=name
    setFieldError(prevState => ({ ...prevState,  Abstractor:''}))
  }else if(type == "UnderWriter"){ 
    contactRef1.current.UnderWriter=name
    setFieldError(prevState => ({ ...prevState,  UnderWriter:''}))
  }else if(type == "Escrow"){ 
    contactRef1.current.Escrow=name
    setFieldError(prevState => ({ ...prevState,  Escrow:''}))
  }else if(type == "EscrowAssistant"){ 
    contactRef1.current.EscrowAssistant=name
    setFieldError(prevState => ({ ...prevState,  EscrowAssistant:''}))
  }else if(type == "MarketRep"){ 
    contactRef1.current.MarketRep=name
    setFieldError(prevState => ({ ...prevState,  MarketRep:''}))
  }else if(type == "MarketRep2"){ 
    contactRef1.current.MarketRep2=name
  }else if(type == "PurchaseSide"){ 
    contactRef1.current.PurchaseSide=name
  }else if(type == "Note"){ 
    contactRef1.current.Note=name
  }else if(type == "productname"){ 
    contactRef1.current.productname=name
  }
  

};

const clearAll = () => {
  contactRef1.current = {"filename": "","refifilename":"","otherfilename": "","uploadType":"","template": "","transaction": "","marketingSource": "","Abstractor": "","UnderWriter": "","Escrow": "","EscrowAssistant": "","MarketRep": "","contacts":[],"productname":""}

  setFilename1('');
  setFilename2('');
  setFilename3('');
  setActiveFile(prevState => ({ ...prevState,  show:0,fileClass1:'dashborder-card fileInactive',fileClass2:'dashborder-card'}))
  setFieldError(prevState => ({ ...prevState,  TranType:'',Marketing:'',Abstractor: "",UnderWriter: "",Escrow: "",EscrowAssistant: "",MarketRep: ""}))

  selectInputRef1.current.setValue(null)
  selectInputRef2.current.setValue(null)
  selectInputRef3.current.setValue(null)
  selectInputRef4.current.setValue(null)
  selectInputRef5.current.setValue(null)
  selectInputRef6.current.setValue(null)
  selectInputRef7.current.setValue(null)
  selectInputRef8.current.setValue(null)
  selectInputRef9.current.setValue(null)

};

console.log("type1",type1)
console.log("type2",type2)

const submitAll = ()=>{

  if(activeFile.show==1){

    var data1 = {...contactRef1.current} 
    data1.filename = filename1
    data1.refifilename = filename2
    data1.otherfilename = filename3

    if(type1==1){data1.uploadType = 1};
    if(type2==2){data1.uploadType = 2};
    createOrderDoc(data1)
  }
  else if(activeFile.show==2){

    var data = {...contactRef1.current} 
    data.refifilename = filename2
    data.otherfilename = filename3
    if(type1==1){data.uploadType = 1};
    if(type2==2){data.uploadType = 2};
    createOrderDoc(data)
    clearAll()

  }
}

const createOrderDoc = async (newOrder) => {
  console.log("detail",newOrder)

  if(toastId.current === null){
    toastId.current = toast.loading('Upload in progress',{   style: { background: '#002dff',color:"#fff" } });
  }else{
    toast.update(toastId.current)
  }

  setPreviewModel(prevState => ({ ...prevState,  show:false}))
  
  var tempJSON = JSON.stringify([newOrder])
  let tempURL = API.processUplDoc
  var response = await POST(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
  console.log("response ...",response);

  if(response != null)
  {
    ocractivityRefresh(response.activityId)
  }else{
    toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error",isLoading:false})
    toast.done(toastId.current)
    setConfirmyes(prevState => ({ ...prevState,  'show':true,'message':"There was a server problem. Please try again."}))
  }
};

const  ocractivityRefresh= async (activityid) => {

  let ocractiviry = API.ocrActivitybyid+"?activityid="+activityid+"&type=json"

  var interval = setInterval( async () => {

    var response = await GET(ocractiviry).catch((e) => { console.log("Get ERR", e) })
    if(response.code == 100 || response.activity.precentage == 100 || response.activity.precentage < 0)
    {
      toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false})
      toast.done(toastId.current)
      clearInterval(interval)   
      
      var message ="";

      if(response.activity.message == "" && response.activity.precentage==100)
        message = "Order created successfully."
      else if(response.activity.message == "" && response.activity.precentage<0)
        message = "Order failed."
      else 
      message = response.activity.message

      setConfirmyes(prevState => ({ ...prevState,  'show':true,'message':message}))

    }
    else{
    }
  }, 10000);

  return () => clearInterval(interval);

};


const toggleYes = () => {
  setConfirmyes(prevState => ({ ...prevState,  'show':false,'message':''}))
  window.location = '/admin/activity'
};



return (
  <>

  { 
    loader && 
    <div style={{position: "absolute",background: "#d8f0ff5c",width: "100%",height: "100vh",zIndex:1}} >
    <div style={{position: "absolute",top: "45vh",bottom: "auto",left: "50%",zIndex:1}}>
    <Loader type="spinner-cub" bgColor="#2a879e" color="#2a879e" size={50}/>
    </div>
    </div>
  }

  <div className="content">
  <Row>
  <Col md="12">
  <Card>
  <CardHeader>
  <CardTitle><h4 className="title">Upload Document</h4></CardTitle>
  </CardHeader>
  <div className='bg-gra-plus' style={{ margin: "0px 15px 0px"}}> </div>
  <div className="border-new">
  <CardBody>
  <Row>

  <Col md="4" >
  <Card className={activeFile.fileClass1}>
  <CardHeader>
  Purchase Contact
  </CardHeader>
  <CardBody>
  { 
    activeFile.show == 1 &&    
    <form id="form-file-upload" onDragEnter={(e)=>handleDrag(e,1)} onSubmit={(e) => e.preventDefault()} >
    <input ref={inputRef1} type="file" id="input-file-upload" multiple={true} onChange={(e)=>handleChange(e,1)} />
    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" } onClick={()=>{fileSelected.current=1}}>
    <div>
    <button className="upload-button" onClick={onButtonClickref1}><i className="tim-icons icon-cloud-upload-94" /> </button>
    <p>Drop Purchase Contract Here</p>
    {
      filename1 !='' && <p>FileName: {filename1}</p>
    }
    </div> 
    </label>
    { dragActive && <div id="drag-file-element" onDragEnter={(e)=>handleDrag(e,1)} onDragLeave={(e)=>handleDrag(e,1)} onDragOver={(e)=>handleDrag(e,1)} onDrop={(e)=>handleDrop(e,1)}></div> }
    </form>
  }
  { 
    activeFile.show != 1 &&
    <form id="form-file-upload">
    <label id="label-file-upload" >
    <div>
    <p>Drop Purchase Contract Here</p>

    </div> 
    </label>
    </form>
  }


  </CardBody>
  </Card>
  </Col>

  <Col md="4">
  <Card className={activeFile.fileClass2}>
  <CardHeader>
  Title Order
  </CardHeader>
  <CardBody>

  {
    activeFile.show != 0 &&
    <form id="form-file-upload" onDragEnter={(e)=>handleDrag(e,2)} onSubmit={(e) => e.preventDefault()} >
    <input ref={inputRef2} type="file" id="input-file-upload" multiple={true} onChange={(e)=>handleChange(e,2)} />
    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" } onClick={()=>{fileSelected.current=2}}> 
    <div>
    <button className="upload-button" onClick={onButtonClickref2}><i className="tim-icons icon-cloud-upload-94" /> </button>
    <p>Drop Refi Contract Here</p>
    {
      filename2 !='' && <p>FileName: {filename2}</p>
    }
    </div> 
    </label>
    { dragActive && <div id="drag-file-element" onDragEnter={(e)=>handleDrag(e,2)} onDragLeave={(e)=>handleDrag(e,2)} onDragOver={(e)=>handleDrag(e,2)} onDrop={(e)=>handleDrop(e,2)}></div> }
    </form>

  }
  { 
    activeFile.show == 0 &&
    <form id="form-file-upload">
    <label id="label-file-upload" >
    <div>
    <p>Drop Refi Contract Here</p>

    </div> 
    </label>
    </form>
  }

  </CardBody>
  </Card>
  </Col>


  <Col md="4">
  <Card className={activeFile.fileClass3}>
  <CardHeader>
  Other Files
  </CardHeader>
  <CardBody>
  
  {
    activeFile.show != 0 &&
    <form id="form-file-upload" onDragEnter={(e)=>handleDrag(e,3)} onSubmit={(e) => e.preventDefault()}>
    <input ref={inputRef3} type="file" id="input-file-upload" multiple={true} onChange={(e)=>handleChange(e,3)} />
    <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" } onClick={()=>{fileSelected.current=3}}>
    <div>

    <button className="upload-button" onClick={onButtonClickref3}> <i className="tim-icons icon-cloud-upload-94" /> </button>
    <p>Drop Other Files Here</p>
    {
      filename3 !='' && <p>FileName: {filename3}</p>
    }  
    </div> 
    </label>
    { dragActive && <div id="drag-file-element" onDragEnter={(e)=>handleDrag(e,3)} onDragLeave={(e)=>handleDrag(e,3)} onDragOver={(e)=>handleDrag(e,3)} onDrop={(e)=>handleDrop(e,3)}></div> }
    </form>
  }
  { 
    activeFile.show == 0 &&
    <form id="form-file-upload">
    <label id="label-file-upload" >
    <div>
    <p>Drop Other Files Here</p>

    </div> 
    </label>
    </form>
  }

  </CardBody>
  </Card>
  </Col>

  </Row>
  <Row>
  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Transaction Type</label>   
  <Select ref={selectInputRef1} className={fieldError.TranType} options={TransType} style={{padding:"0 10px"}}  
  onChange={(e)=>{
    if(e != null){
      onChangeTransctionType(e.value)
      onContactRef('Transaction',e.label,e.value)
    }
  }} 
  />
  </Col>

  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Abstractor</label>   
  <Select ref={selectInputRef2}  className={fieldError.Abstractor} options={Abstractor} style={{padding:"0 10px"}} onChange={(e)=>{  if(e != null) onContactRef('Abstractor',e.label,e.value)}} />
  </Col>
  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Underwriter</label>   
  <Select ref={selectInputRef3}  className={fieldError.UnderWriter} options={UnderWriter} style={{padding:"0 10px"}} onChange={(e)=>{  if(e != null) onContactRef('UnderWriter',e.label,e.value)}} />
  </Col>
  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Escrow Pod/Closer</label>   
  <Select  ref={selectInputRef4}  className={fieldError.Escrow} options={Escrow} style={{padding:"0 10px"}} onChange={(e)=>{  if(e != null) onContactRef('Escrow',e.label,e.value)}} />
  </Col>

  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Pre-Closer/Escrow Assistant </label>   
  <Select ref={selectInputRef5}  className={fieldError.EscrowAssistant} options={Escrow} style={{padding:"0 10px"}} onChange={(e)=>{ if(e != null) onContactRef('EscrowAssistant',e.label,e.value)}}  />
  </Col>
  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Marketing Source</label>   
  <Select ref={selectInputRef6} className={fieldError.Marketing} options={Marketing} style={{padding:"0 10px"}} onChange={(e)=>{ if(e != null) onContactRef('MarketSource',e.label,e.value)}} />
  </Col>
  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Marketing Rep</label>   
  <Select ref={selectInputRef7}  className={fieldError.MarketRep} options={Escrow} style={{padding:"0 10px"}} onChange={(e)=>{  if(e != null) onContactRef('MarketRep',e.label,e.value)}} />
  </Col>
  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Marketing Rep 2</label>   
  <Select ref={selectInputRef8}  options={Escrow} style={{padding:"0 10px"}} onChange={(e)=>{  if(e != null) onContactRef('MarketRep2',e.label,e.value)}} />
  </Col>

  {
    activeFile.show !=2 && <Col md="3" style={{marginBottom:"0.25rem"}}>
    <label style={{fontSize: "0.8rem"}}>Purchase Side</label>   
    <Select ref={selectInputRef9} options={PurchaseSide} style={{padding:"0 10px"}} onChange={(e)=>{  if(e != null) onContactRef('PurchaseSide',e.label,e.value)}} />
    </Col>
  }

  <Col md="3" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Project Name</label>   
  <Input placeholder="" type="text" style={{padding:"0 10px"}} onBlur={(e)=>{ onContactRef('productname',e.target.value,e.target.value)}} />
  </Col>

  <Col md="12" style={{marginBottom:"0.25rem"}}>
  <label style={{fontSize: "0.8rem"}}>Note</label>   
  <Input  defaultValue=""
  placeholder="Enter the notes..."  rows="4"  type="textarea" style={{padding:"10px"}} onBlur={(e)=>{ onContactRef('Note',e.target.value,e.target.value)}} />
  </Col>
  <Col md="12" >

  <Button color="success" style={{width: "75px",marginRight: "0px",float: "right",alignSelf: "flex-end",padding:"6px 0px"}} onClick={()=>openPreview()} >Create</Button>
  <Button className="btn-simple"  style={{width: "75px",fontWeight: "normal",marginRight: "5px",float: "right",alignSelf: "flex-end",padding:"6px 0px"}} onClick={()=>clearAll()}>Clear All</Button>

  </Col>
  </Row>
  </CardBody>
  </div>
  </Card>
  </Col>
  </Row>
  </div>

  <Modal isOpen={errorModel.show} size="md">
  <ModalHeader style={{ borderBottom: "1px solid #ddd",paddingBottom: "5px"}} ><h2 style={{color:"#3c87d4"}}>No Document Selected!</h2></ModalHeader>
  <ModalBody className="text-center" style={{paddingTop:"15px"}}>
  <p className="text-secondary">{errorModel.message}</p>
  </ModalBody>
  <ModalFooter style={{justifyContent:"right",padding:"8px"}}>
  {/*<Button color="success" style={{marginRight:"10px",padding:"6px 10px"}} onClick={()=>setPreviewModel(prevState => ({ ...prevState,  show:true}))}>Proceed</Button>*/}
  <Button color="secondary" style={{marginRight:"10px",padding:"6px 10px"}} onClick={()=>setErroModel(prev=>({...prev,show:false,message:""}))}>Close</Button>
  </ModalFooter>
  </Modal>

  <Modal isOpen={previewModel.show} size="lg">
  <ModalHeader style={{ borderBottom: "1px solid #ddd",paddingBottom: "5px"}} >Preview</ModalHeader>
  <ModalBody className="text-left ml-2" style={{paddingTop:"15px"}}>

  {
    filename1 != '' && <Row>
    <Col>File name purchase</Col>
    <Col>{filename1}</Col>
    </Row>
  }
  {
    filename2 != '' && <Row>
    <Col>File name title</Col>
    <Col>{filename2}</Col>
    </Row>
  }
  {
    filename3 != '' && <Row>
    <Col>File name other</Col>
    <Col>{filename3}</Col>
    </Row>
  }

  { 
    contactRef1.current.contacts.length > 0 && contactRef1.current.contacts.map(item=>{
      return(
      <Row style={{borderBottom: "1px solid #ddd"}}>
      <Col>{item.type}</Col>
      <Col>{item.name}</Col>
      </Row>
      )

    })

  }

  </ModalBody>
  <ModalFooter style={{justifyContent:"right",padding:"8px"}}>
  <Button color="success" style={{marginRight:"10px",padding:"6px 10px"}} onClick={()=>{submitAll()}}>save</Button>
  <Button color="secondary" style={{marginRight:"10px",padding:"6px 10px"}} onClick={()=>setPreviewModel(prev=>({...prev,show:false}))}>Close</Button>
  </ModalFooter>
  </Modal>

  <Modal className="addorder" isOpen={confirmyes.show} toggle={toggleYes}>
  <ModalBody style={{paddingTop:"45px"}}><p style={{textAlign:"center",color:"#787a79","fontWeight":600,"fontSize":"20px","marginBottom":"1px"}}>{confirmyes.message}</p></ModalBody>
  <ModalFooter style={{ justifyContent: "right", padding:"8px"}}>
  <Button color="success" style={{marginRight:"10px",padding:"6px 10px"}} onClick={()=>{toggleYes()}}>Ok</Button>
  </ModalFooter>
  </Modal>

  </>
  );
}

export default Document;

