import React, { useState, useEffect, useRef } from 'react';
import {
  Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button, CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import Select, { components } from 'react-select';
import { Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Trash from '../../assets/img/trash.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GET, POST, PUT, DELETE } from '../../Services';
import dataHandler from '../../dataHandler';
import API from '../../Service/Api';

const empsets = { _id: null,client_id:null,client_name:null, name: null, escrow_pod: null, EscrowOdefault:{}, escrow_assistant: null,EscrowAdefault:{},marketing_rep1: null,marketing1default:{},marketing_rep2: null,marketing2default:{},index:0 };

function Userpop(props) {

  console.log("pppp", props.data);
  console.log("pppp", props.selected);

  const [fieldError, setFieldError] = React.useState({ client_name:'' });

  const [client, setClient] = useState({ _id: null,client_id:null,client_name:null});

  const [empRemoved, setEmpRemoved] = useState([]);
  
  const removedItem = useRef([]);

  const [data, setData] = useState([
    { _id: null,client_id:null,client_name:null, name: null,email: null, escrow_pod: null,EscrowOdefault:{}, escrow_assistant: null,EscrowAdefault:{},marketing_rep1: null,marketing1default:{},marketing_rep2: null,marketing2default:{},index:0 },
    { _id: null,client_id:null,client_name:null, name: "Default All",email: null, escrow_pod: null,EscrowOdefault:{}, escrow_assistant: null,EscrowAdefault:{},marketing_rep1: null,marketing1default:{},marketing_rep2: null,marketing2default:{},index:1 }
    ]);

  const toastId = useRef(null);

  useEffect(() => {
    if (props.data && props.data.length>0) {
      setData(props.data)
    }
  }, [])

  const toggle = () => {
    props.setUserModal(prevState => ({ ...prevState, show: false }))
  };

  const addEmployee = () => {
    var tempdata = [...data];
    var popped = tempdata.pop()
    var tempset={...empsets}
    tempset.index = tempdata.length
    
    tempset.client_name = tempdata && tempdata[0] && tempdata[0].client_name?tempdata[0].client_name:null

    tempdata.push(tempset)
    popped.index = tempdata.length;
    tempdata.push(popped)
    setData(tempdata)
  };

  const changeValue = (value,key,index) =>{
    // if(data.length != (index+1))
    // {
      var tempdata = [...data];
      tempdata[index][key]=value;
      setData(tempdata)
    // }
  }; 

  const selectUser = (value,key1,key2,index) => {
    var tempdata = [...data];
    tempdata[index][key1]=value.label;
    tempdata[index][key2]=value;
    setData(tempdata)
  };

  const changeClientValue = (value,key) =>{
    setClient(prevState => ({ ...prevState, [key]:value }))
      var tempdata = [...data];
      tempdata.map((item)=>{ item.client_name = value; })
      setData(tempdata)
  }; 

  const deleteClientException = (index) =>{
    var tempdata = [...data];
    var removed = tempdata.splice(index,1);
    setData(tempdata)
    if(removed[0]._id != null)
      removedItem.current.push(removed[0]._id);
  };  

  const saveAbstractor = async () => {

    setFieldError(prevState => ({ ...prevState, client_name: ''}))
    if (data[0].client_name == null || data[0].client_name == '') {
      setFieldError(prevState => ({ ...prevState, client_name: 'border border-danger' }))
      return;
    }

      if (toastId.current === null) {
        toastId.current = toast.loading('upload in progress');
      } else {
        toast.update(toastId.current)
      }

      var temp = {
        "client":client,
        "employee":data,
        "removed":removedItem.current
      }

      var tempJSON = JSON.stringify(temp)

      let tempURL = API.User + "?token=" + dataHandler.getToken()
      var response = await POST(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
      console.log("response ...", response);

      if (response != null) {
        toast.update(toastId.current, { rendor: "Successfully saved", type: "success", isLoading: false, autoClose: 5000, })
        toast.done(toastId.current)

        toggle();
        props.getdata()

      } else {
        toast.update(toastId.current, { rendor: "Please fill all the fields", type: "error", autoClose: 5000, isLoading: false })
        toast.done(toastId.current)
      }


    // else {

    //   if (toastId.current === null) {
    //     toastId.current = toast.loading('upload in progress');
    //   } else {
    //     toast.update(toastId.current)
    //   }

    //   var tempJSON = JSON.stringify(data)

    //   let tempURL = API.User + '/' + data._id + "?token=" + dataHandler.getToken()
    //   var response = await PUT(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
    //   console.log("response ...", response);

    //   if (response != null) {
    //     toast.update(toastId.current, { rendor: "Successfully saved", type: "success", isLoading: false, autoClose: 5000, })
    //     toast.done(toastId.current)
    //     toggle();
    //     props.getdata()
    //   } else {
    //     toast.update(toastId.current, { rendor: "Please fill all the fields", type: "error", autoClose: 5000, isLoading: false })
    //     toast.done(toastId.current)
    //   }
    // }
  

  };


  return (
    <div>
      <Modal className="addorder" isOpen={props.show} size="xl" centered>
        <ModalHeader toggle={toggle} style={{ borderBottom: "1px solid #ddd", paddingBottom: "5px" }}>Details</ModalHeader>
        <ModalBody style={{ paddingTop: "15px" }}>

          <Row className="py-1">
            <Col md="6">
            <label>Client Name</label>
            <Input className={fieldError.client_name} placeholder="Client Name" type="text" style={{ padding: "21px 10px" }} value={data[0].client_name} onChange={(e) => { changeClientValue(e.target.value, 'client_name'); }} />
            </Col>
          </Row>

          <Row className="py-1">
            <Col md="6">
            <Button className="btn btn-info btn-sm"><span style={{fontSize:"14px"}} onClick={addEmployee}>Add Employee</span></Button>
            </Col>
          </Row>

          { data.map((item,index)=>(
           <> 
          { item.name == 'Default All' && <p></p> }
          <div className=""  style={{borderBottom:"1px solid #e7e7e7",padding:"2px",marginBottom:"2px",backgroundColor:(props.selected && item._id==props.selected._id)?"#cae4f6fc":"#fff"}}>
            <Row className="py-1" key={index}>
              <Col md="4">
                <Input placeholder="Employee Name" type="text" style={{ padding: "21px 10px" }} value={item.name} onChange={(e) => { changeValue(e.target.value, 'name',index); }} />
              </Col>
              <Col md="4">
                <Input placeholder="Employee Email" type="text" style={{ padding: "21px 10px" }} value={item.email} onChange={(e) => { changeValue(e.target.value, 'email',index); }} />
              </Col>

              <Col md="3">
                <Select placeholder="Escrow Closer" options={props.Escrow} style={{ padding: "0 10px" }} onChange={e => selectUser(e,'escrow_pod','EscrowOdefault',index)} value={item.EscrowOdefault && Object.keys(item.EscrowOdefault).length === 0? '' : item.EscrowOdefault} />
              </Col>
              { item.name != 'Default All' && <></>}
               <Col md="1">
                <Button  className="btn-plus" ><img src={Trash} style={{height:"25px",objectFit:"contain"}} onClick={()=>deleteClientException(item.index)}/></Button>
                </Col>
            </Row>

            <Row className="py-1" key={index}>
              <Col md="4">
                <Select placeholder="Escrow Assistant" options={props.Escrow} style={{ padding: "0 10px" }} onChange={e => selectUser(e,'escrow_assistant','EscrowAdefault',index)} value={item.EscrowAdefault && Object.keys(item.EscrowAdefault).length === 0? '' : item.EscrowAdefault}/>
              </Col>
              <Col md="4">
                <Select placeholder="Marketing Rep 1" options={props.Escrow} style={{ padding: "0 10px" }} onChange={e => selectUser(e,'marketing_rep1','marketing1default',index)} value={item.marketing1default && Object.keys(item.marketing1default).length === 0? '' : item.marketing1default}/>
              </Col>
              <Col md="3">
                <Select placeholder="Marketing Rep 2" options={props.Escrow} style={{ padding: "0 10px" }} onChange={e => selectUser(e,'marketing_rep2','marketing2default',index)} value={item.marketing2default && Object.keys(item.marketing2default).length === 0? '' : item.marketing2default}/>
              </Col>
                
            </Row>
          </div>

          </>
          )) }

      

        </ModalBody>
        <ModalFooter style={{ justifyContent: "center", padding: "8px" }}>
          <Button color="success" style={{ marginRight: "10px", padding: "6px 10px" }} onClick={saveAbstractor} >Submit</Button>
          <Button color="secondary" style={{ marginRight: "10px", padding: "6px 10px" }} onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>

    </div>
  );
}

export default Userpop;