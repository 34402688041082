import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button,CardFooter,
CardText,
FormGroup,
Form,
Input,
Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import { Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import countryimg from '../../assets/img/country.png';
import abstractimg from '../../assets/img/abstract.png';
import flagimg from '../../assets/img/flag.png';
import dislikeimg from '../../assets/img/dislike.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {GET,POST,PUT,DELETE} from '../../Services';
import API from '../../Service/Api';


function Statepop(props) {

  const [data, setData] = React.useState([]);
  const paramRef = useRef({search:'',sortby:'name',sortin:1,page:1,limit:10,totalSize:0});

  const [addStateModal, setAddStateModal] = useState({show:false, _id:null,name:null,abbr:null});
  const [fieldError, setFieldError] = React.useState({name:'',abbr:''});
  const toastId = useRef(null);

  function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
  }

  useEffect(()=>{
    getdata()
  },[])

  const getdata = async()=>{
    var param = objectToQueryString(paramRef.current);
    var url=API.State+"?"+param+"&token="+localStorage.getItem('atoken')
    var response = await GET(url).catch((e) => console.log("Fetch Error", e))

    if (response != null && response.code != null && response.code == 200) {
      console.log(response);
      paramRef.current.page = response.page?response.page:1
      paramRef.current.totalSize = response.count?response.count:0
      setData(response.data)
    }
  }

  const Addstateemty = [
  {
    dataField: "name",
    text: "State",
    headerClasses: 'th-first1',
  },

  {
    dataField: "Action",
    text: "",
    headerClasses: 'th-last1',
    formatter: (cellContent, row) => (
      <div className="">
      <Button color="success" style={{fontSize:"12px",padding:"1px 12px",margin:"0px 5px"}} onClick={()=>{setAddStateModal(prevState => ({ ...prevState, show:true,_id:row._id, name:row.name,abbr:row.abbr}))}}>Edit</Button>

        </div>
        )
    }
    ];

const toggle = () =>{
  props.setStateModal(prevState => ({ ...prevState, show:false }))
};

const searchState = (e) =>{
  paramRef.current.search = e.target.value; 
  paramRef.current.page = 1; 
  getdata();
};  

const toggleAddState = () =>{
  setAddStateModal(prevState => ({ ...prevState, show:false,_id:null,name:null }))
};

const changeValue = (value,key) =>{
  setAddStateModal(prevState => ({ ...prevState, [key]:value }))
};  

const saveState = async () =>{
  setFieldError(prevState => ({ ...prevState, name:''}))
  
  if(addStateModal.name == null){
    setFieldError(prevState => ({ ...prevState, name:'border border-danger'}))
    return;
  }
  if(addStateModal.abbr == null){
    setFieldError(prevState => ({ ...prevState, abbr:'border border-danger'}))
    return;
  }

  if(addStateModal._id == null){

    if(toastId.current === null){
      toastId.current = toast.loading('upload in progress');
    }else{
      toast.update(toastId.current)
    }

    var tempJSON = JSON.stringify({ name:addStateModal.name,abbr:addStateModal.abbr })


    let tempURL = API.State
    var response = await POST(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
    console.log("response ...",response);


    if(response != null)
    {
      toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false,autoClose:5000,})
      toast.done(toastId.current)
      getdata()
      setAddStateModal(prevState => ({ ...prevState, show:false,_id:null,name:null}))
    }else{
      toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error", autoClose:5000, isLoading:false})
      toast.done(toastId.current)
    }

  }else{

    if(toastId.current === null){
      toastId.current = toast.loading('upload in progress');
    }else{
      toast.update(toastId.current)
    }

    var tempJSON = JSON.stringify({ name:addStateModal.name,abbr:addStateModal.abbr})

    let tempURL = API.State+'/'+addStateModal._id
    var response = await PUT(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
    console.log("response ...",response);

    if(response != null)
    {
      toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false,autoClose:5000,})
      toast.done(toastId.current)
      getdata()
      setAddStateModal(prevState => ({ ...prevState, show:false,_id:null,name:null}))
    }else{
      toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error", autoClose:5000, isLoading:false})
      toast.done(toastId.current)
    }
  }
};

const handleTableChange = (e,data) =>{
  paramRef.current.page = data.page
  getdata()
};


return (
  <div>


  <Modal className="addorder" isOpen={props.show} toggle={toggle} size="lg" >
  <ModalHeader   style={{ borderBottom: "1px solid #ddd",paddingBottom: "5px"}}> Add State</ModalHeader>
  <ModalBody className="addstateonly" style={{paddingTop:"15px"}}>
  <div className="content">
  <Card style={{border:"0",marginBottom:"0px"}}>

  <CardBody>


  <Row className="py-1">

  <Col md="12">          

  <div className='bg-gra-plus modelplus' style={{ padding: "5px 8px",height:"auto"}}>
 


  <Input placeholder="Search State" type="text" style={{padding:"21px 10px"}}  onChange={searchState}/>
   <Button  className="btn-plus" onClick={()=>{setAddStateModal(prevState => ({ ...prevState, show:true}))}} >+</Button>
  </div>





  <ToolkitProvider
  bootstrap4
  keyField="addstateid"
  data={ data }
  columns={ Addstateemty }
  search={ false }
  >
  { 
    props => (
      <div className='search-select-bar seach-select-bar-abstractor modeltype'>
      <BootstrapTable
      classes="Home-table1 abstractortable"
      { ...props.baseProps }
        remote
        pagination={paginationFactory({ page:paramRef.current.page, sizePerPage: paramRef.current.limit,sizePerPageList:[],totalSize:paramRef.current.totalSize,paginationSize:10})}
      striped
      hover
      condensed
       // selectRow={ selectRow }
      style={{marginBottom:"0px"}}
      onTableChange={handleTableChange}
      />
      </div>
      )
  }
  </ToolkitProvider>


  </Col>
  </Row>
  </CardBody>
  </Card>
  </div>
  </ModalBody>
          {/* <ModalFooter style={{ justifyContent: "center", padding:"8px"}}>
          <Button color="success" style={{marginRight:"10px",padding:"6px 10px"}} >Submit</Button>
          <Button color="secondary" style={{marginRight:"10px",padding:"6px 10px"}}>Cancel</Button>
          </ModalFooter> */}
  </Modal>

  <Modal className="addorder" isOpen={addStateModal.show} size="md" >
  <ModalHeader   style={{ borderBottom: "1px solid #ddd",paddingBottom: "5px"}}>{ addStateModal._id==null && 'Add State'}{addStateModal._id!=null && 'Edit State'}</ModalHeader>
  <ModalBody  style={{paddingTop:"15px"}}>
  <div className="content">
  <Card style={{border:"0",marginBottom:"0px"}}>

  <CardBody>
  <Row>

  <Col md="12">
  <Input placeholder="State Name" className={fieldError.name} type="text" style={{padding:"21px 10px"}} value={addStateModal.name} onChange={(e)=>{changeValue(e.target.value,'name');}}/>
  </Col>
  <Col md="12">
  <Input placeholder="Abbreviation" className={fieldError.abbr} type="text" style={{padding:"21px 10px"}} value={addStateModal.abbr} onChange={(e)=>{changeValue(e.target.value,'abbr');}}/>
  </Col>
  <Col md="12" className="text-center">
  <Button  color="success" style={{marginTop:"10px",padding:"6px 10px"}} onClick={saveState} >Save</Button>
  <Button  color="secondary" style={{marginTop:"10px",padding:"6px 10px"}} onClick={toggleAddState}>Cancel</Button>
  </Col>

  </Row>

  </CardBody>
  </Card>
  </div>
  </ModalBody>

  </Modal>


  </div>
  );
}

export default Statepop;