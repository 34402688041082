let token = '';

export default {
    setToken(arg) {
        token = arg;
    },
    getToken() {
        return token;
    }
};
