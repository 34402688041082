
export const GET = async (url) => {
    const response = await fetch(url, { method: 'GET'})
    const responseJson = await response.json()
    return responseJson    
}

export const POST = async (url, params) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: params,
        timeout: 300000
    })
    const responseJson = await response.json()
    return responseJson
}

export const PUT = async (url, params) => {
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: params,
        timeout: 300000
    })
    const responseJson = await response.json()
    return responseJson
}

export const DELETE = async (url) => {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    const responseJson = await response.json()
    return responseJson
}

export const POSTFILE = async (url, params) => {
    const response = await fetch(url, {
        method: 'POST',
        body: params,
    })
    const responseJson = await response.json()
    return responseJson
}

