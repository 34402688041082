
const BASE_DOMAIN = process.env.REACT_APP_BASE_DOMAIN
const BASE_DOMAIN_PDFURL = process.env.REACT_APP_BASE_DOMAIN_PDFURL

const SECUREPORTAL_URL = process.env.REACT_APP_BASE_DOMAIN_SECUREPORTAL

const ABSTRACTOR_URL = process.env.REACT_APP_BASE_DOMAIN_ABSTRACTOR

const API = {

   updateUiOrder: BASE_DOMAIN + "api/orders/updateGUIOrder",
   
   DropdownServies: BASE_DOMAIN + "/api/orders/dropdowns",

   updateOrder: BASE_DOMAIN + "api/orders/updateOrder",
   order: BASE_DOMAIN + "api/orders",

   uploadDocxToPdf:SECUREPORTAL_URL+"v1/docUploadToPdf",

   // order: BASE_DOMAIN + "api/orders",
   aorder: BASE_DOMAIN + "api/order",
   createOrder: BASE_DOMAIN + "api/createOrder",
   processUplDoc: BASE_DOMAIN + "api/orders/procesOCR",
   ocrActivitybyid: BASE_DOMAIN + "api/ocractivitybyid",
   // processUplDoc: BASE_DOMAIN + "api/Orders/processUplDoc",
   activity: BASE_DOMAIN + "api/ocractivity",

   downloadFile: BASE_DOMAIN + "api/downloadFile",
   
   

   //ABSTRACTOR

   Auth: ABSTRACTOR_URL + "apiv2/signIn",
     County: ABSTRACTOR_URL + "apiv2/v1/county",
   CountyDropdown: ABSTRACTOR_URL + "apiv2/v1/county_dropdown",

   State: ABSTRACTOR_URL + "apiv2/v1/state",
   Abstractor: ABSTRACTOR_URL + "apiv2/v1/abstractor",

   User: ABSTRACTOR_URL + "apiv2/v1/user",
   userClient: ABSTRACTOR_URL + "apiv2/v1/userClient",
   
}


export default API;

