import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button,CardFooter,
CardText,
FormGroup,
Form,
Input,
Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import { Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import countryimg from '../../assets/img/country.png';
import abstractimg from '../../assets/img/abstract.png';
import flagimg from '../../assets/img/flag.png';
import dislikeimg from '../../assets/img/dislike.png';

import Statepop from './Statepop';
import Countypop from './Countypop';
import Abstractorpop from './Abstractorpop';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {GET,POST,PUT,DELETE} from '../../Services';
import dataHandler from '../../dataHandler';
import API from '../../Service/Api';

const titles = [
  { value: 'Insured Title', label: 'Insured Title' },
  { value: 'Uninsured Title', label: 'Uninsured Title' },
  { value: 'Both (Insured & Uninsured Title)', label: 'Both (Insured & Uninsured Title)' }
  ]

const { SearchBar } = Search;


function Abstractor() {

  const [abstractModal, setAbstractModal] = React.useState({show:false,data:null});
  const [deleteModal, setDeleteModal] = React.useState({show:false,data:null});
  const [stateModal, setStateModal] = React.useState({show:false});
  const [countyModal, setCountyModal] = React.useState({show:false});

  
  const [states, setStates] = React.useState([]);

  const [data, setData] = React.useState([]);
  const paramRef = useRef({search:'',state:'',county:'',underwritter:'',sortby:'name',sortin:1,page:1,limit:10,totalSize:0});

  const [countyOptions, setCountyOptions] = React.useState([]);
  
  const [UnderWriter,setUnderWriter] = useState([])

  const toastId = useRef(null);

  useEffect(()=>{

    if(localStorage.getItem('atoken') == '' || localStorage.getItem('atoken') == null){
      authentications()
    }

  },[])
  

  //State dropdowns. 
  const authentications = async()=>{
    console.log("authentication triggered");
    
    if(toastId.current === null){
      toastId.current = toast.loading('Authenticating');
    }

    var tempJSON = JSON.stringify({email:process.env.REACT_APP_BASE_DOMAIN_EMAIL,password:process.env.REACT_APP_BASE_DOMAIN_RAWPASSWORD})

    let tempURL = API.Auth;
    var response = await POST(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
    console.log("response ...",response);

    if(response.code == 200)
    {
      toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false,autoClose:5000,})
      toast.done(toastId.current)
      
      // dataHandler.setToken(response.token)
      localStorage.setItem('atoken', response.token);

      Loaddata();
    }else{
      toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error", autoClose:5000, isLoading:false})
      toast.done(toastId.current)
    }
  }

  useEffect(() => {  
     getDropdowns();
  },[]);

   const getDropdowns = async () => {
    if(UnderWriter.length == 0){
  
      let underwriterURL = API.DropdownServies+"?contact=Underwriter&type=json"
      var response2 = await GET(underwriterURL).catch((e) => { console.log("Get ERR", e) })
      if(response2 != null && response2.status == 200)
      {
        let result1 = response2.data.map(item=>({value:item.lookupcode,label:item.name}))
        result1.unshift( {'value':'','label':'Select'} );
        setUnderWriter(result1)
      }
    } 
  }
  


  useEffect(()=>{
    if(localStorage.getItem('atoken') != ''){
      Loaddata();
    }
  },[])

  const Loaddata = async()=>{
    getstates()
    getdata()
  }

  //State dropdowns. 
  const getstates = async()=>{
    console.log("gggggg=",localStorage.getItem('atoken'));

    var url=API.State+"?search=&skip=&limit=200&sortby=name&sortin=1&page=1&token="+localStorage.getItem('atoken')
    var response = await GET(url).catch((e) => console.log("Fetch Error", e))

    if (response != null && response.code != null && response.code == 200) {
      var item = response.data.map((itm)=>({'value':itm._id,'label':itm.name})) 
      item.unshift( {'value':'','label':''} );
      setStates(item)
    }
  }

  const getCounty = async(state)=>{
    var url=API.CountyDropdown+"?search=&skip=&limit=100&sortby=name&sortin=1&page=1&state="+state+"&token="+localStorage.getItem('atoken')
    var response = await GET(url).catch((e) => console.log("Fetch Error", e))

    if (response != null && response.code != null && response.code == 200) {
      var item = response.data.map((itm)=>({'value':itm._id,'label':itm.name})) 
      item.unshift( {'value':'All','label':'All'} );
      setCountyOptions(item)      
    }
  }

  function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
  }

  const getdata = async()=>{
    var param = objectToQueryString(paramRef.current);
    var url=API.Abstractor+"?"+param+"&token="+localStorage.getItem('atoken')
    var response = await GET(url).catch((e) => console.log("Fetch Error", e))

    if (response != null && response.code != null && response.code == 200) {

      paramRef.current.page = response.metadata.page?response.metadata.page:1
      paramRef.current.totalSize = response.metadata.total?response.metadata.total:0

      console.log(response);
      setData(response.data)
    }
  }

  const selectState = (value) =>{
    paramRef.current.state = value;
    getCounty(value);
    getdata();
  };  

  const selectCounty = (e) =>{
    var item = e.map((itm)=>(itm.value)).join(',');
    var result = item.match("All");
    if(result)
      paramRef.current.county = 'All'; 
    else 
    paramRef.current.county = item; 
    getdata();
  }; 

  const searchAbstractor = (e) =>{
    paramRef.current.search = e.target.value; 
    getdata();
  };  

  const deleteAbstractr = async(e) =>{

    console.log(deleteModal.data);

    if(deleteModal.data != null){

      if(toastId.current === null){
        toastId.current = toast.loading('delete in progress');
      }

      let tempURL = API.Abstractor+'/'+deleteModal.data._id+"?token="+localStorage.getItem('atoken')
      var response = await DELETE(tempURL).catch((e) => { console.log("Get ERR", e) })
      console.log("response ...",response);

      if(response != null)
      {
        toast.update(toastId.current,{rendor:"Successfully deleted", type:"success",isLoading:false,autoClose:5000,})
        toast.done(toastId.current)
        setDeleteModal(prevState => ({ ...prevState, show:false,data:null}))
        getdata()

      }else{
        toast.update(toastId.current,{rendor:"Something went wrong", type:"error", autoClose:5000, isLoading:false})
        toast.done(toastId.current)
      }

    }else{
      setDeleteModal(prevState => ({ ...prevState, show:false,data:null}))
    }

  };  

  const handleTableChange = (e,data) =>{
    paramRef.current.page = data.page
    getdata()
  };

  const columns = [ 
  {
    dataField: "abstractor_name",
    text: "Abstractor Name",
    sort: true,
    headerClasses: 'th-first1',
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "state",
    text: "State",
    sort: true,
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "county",
    text: "County",
    sort: true,
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    } 
  },
  {
    dataField: "lookup_code",
    text: "Lookup Code",
    sort: true,
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "titles",
    text: "Insured & Uninsured Title",
    sort: true,
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "Action",
    text: "Action",
    headerClasses: 'th-last1',
    formatter: (cellContent, row) => (
      <div className="">
      <Button color="success" style={{fontSize:"12px",padding:"1px 12px",margin:"0px 5px"}} onClick={()=>{setAbstractModal(prevState => ({ ...prevState, show:true,data:row}))}} >Edit</Button>
      <Button color="danger" style={{fontSize:"12px",padding:"1px 12px",margin:"0px 5px"}} onClick={()=>{setDeleteModal(prevState => ({ ...prevState, show:true,data:row}))}} >Delete</Button>
      </div>
      )
  }
  ];



  return ( 
    <div className="content">
    <Row>
    <Col md="12">
    <Card>
    <CardHeader>
    <CardTitle><h4 className="title">Abstractor</h4></CardTitle>
    </CardHeader>
    <CardBody>
    <div className='bg-gra-plus'>
    <Button  className="btn-plus" onClick={()=>{setAbstractModal(prevState => ({ ...prevState, show:true,data:null }))}} >+</Button>
    <Button  className="btn-plus" onClick={()=>{setStateModal(prevState => ({ ...prevState, show:true }))}} ><img src={flagimg} height="24px"/></Button>
    <Button  className="btn-plus" onClick={()=>{setCountyModal(prevState => ({ ...prevState, show:true }))}} ><img src={countryimg} height="24px"/></Button>
    <Button  className="btn-plus" hidden><img src={abstractimg} height="24px"/></Button>
    <Button  className="btn-plus" hidden><img src={dislikeimg} height="24px"/></Button>
    </div>
    
    <div className="search-filter">
    <label>Filter</label>
    <div className="filterpart-abs">
    <Select placeholder="State" style={{padding:"21px 10px"}} options={states} onChange={e=>selectState(e.value)}/>
    <Select placeholder="County" style={{padding:"21px 10px"}} isMulti options={countyOptions} onChange={selectCounty}/>
    <Input placeholder="Search" type="text" className="search-input"  onChange={searchAbstractor}/>
    </div>
    </div>

    <ToolkitProvider
    bootstrap4
    keyField="orderID"
    data={ data }
    columns={ columns }
    search={ true }
    >
    { 
      props => (
        <div className='search-select-bar seach-select-bar-abstractor'>

        <BootstrapTable
        classes="Home-table abstractortable"
        { ...props.baseProps }
        remote
        pagination={paginationFactory({ page:paramRef.current.page, sizePerPage: paramRef.current.limit,sizePerPageList:[],totalSize:paramRef.current.totalSize,paginationSize:10})}
        striped
        hover
        condensed
        onTableChange={handleTableChange}
        />
        </div>  
        )
    }
    </ToolkitProvider>
    </CardBody>
    </Card>
    </Col>
    </Row>

    <Modal className="addorder" isOpen={deleteModal.show} size="md" >
    <ModalHeader></ModalHeader>
    <ModalBody  style={{paddingTop:"15px"}}>
    <div className="content">
    <Card style={{border:"0",marginBottom:"0px"}}>

    <CardBody>
    <Row>

    <Col md="12" className="text-center">
    <div className="h5 mb-4">
    <span>Are you sure, do you want to delete <span style={{color:"red"}}>"{deleteModal.data?.abstractor_name}"</span> Abstractor?</span>
    </div>
    </Col>
    

    <Col md="12" className="text-center">
    <Button  color="danger" style={{marginTop:"10px",padding:"6px 10px"}} onClick={deleteAbstractr} >Yes,Delete It!</Button>
    <Button  color="info" style={{marginTop:"10px",padding:"6px 10px"}}  onClick={()=>{setDeleteModal(prevState => ({ ...prevState, show:false,data:null}))}}>No</Button>
    </Col>

    </Row>

    </CardBody>
    </Card>
    </div>
    </ModalBody>

    </Modal>

    <Abstractorpop key={abstractModal.show} show={abstractModal.show} data={abstractModal.data} setAbstractModal={setAbstractModal} states={states} titles={titles} getdata={getdata} UnderWriter={UnderWriter}/>
    <Statepop key={stateModal.show} show={stateModal.show} setStateModal={setStateModal} getstates={getstates} states={states} titles={titles}/>
    <Countypop key={states} show={countyModal.show} setCountyModal={setCountyModal} states={states} titles={titles}/>

    </div>
    );
}

export default Abstractor;
