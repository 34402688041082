/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef,useContext } from 'react'
import { OrderContext } from "../contexts/orderContext";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ResizablePanels from '../components/ResizePanel';
// import { Document,Page,pdfjs } from 'react-pdf';

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import type { HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {GET,POST,DELETE} from '../Services';
import API from '../Service/Api';


function Property() {

  const areas=[
  {
    pageIndex: 1,
    height: 8.55401,
    width: 50.1674,
    left: 27.5399,
    top: 15.0772,
  },
  ]

  const { orderInfo, ModifyOrderDetail, SaveOrderDetail } = useContext(OrderContext);
  
  const [data, setdata] = useState()
  const [load, setload] = useState(true)
  console.log("#######",orderInfo);

  const [url, seturl] = useState('');
  const defaultLayoutPluginInstance = defaultLayoutPlugin();


  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zipError, setzipError] = useState(false)

  useEffect(() => { getsdata() }, [orderInfo])           

const getsdata = async () => {

  console.log("orderInfo....",orderInfo)

  if(orderInfo && orderInfo != undefined){
    setdata(orderInfo)
    let tempURL = API.downloadFile+"?filename="+orderInfo.pdfurL1+"&type=json"
    var response = await GET(tempURL).catch((e) => {console.log("Get ERR", e) })
    if(response != null && response.status == "200" )
    {
      let purl =  process.env.REACT_APP_BASE_DOMAIN_PDFURL+''+orderInfo.pdfurL1
      seturl(purl)
    }
    setload(false)
  }
}
  
  const getBasicAuthenticationHeader = (username, password)=>{
    var auth = btoa(username + ":" + password);
    return "Basic " + auth;
  }


  const renderHighlights = (props:RenderHighlightsProps) => (
    <div>
    {areas
    .filter((area) => area.pageIndex === props.pageIndex)
    .map((area, idx) => (
      <div
      key={idx}
      className="highlight-area"
      style={Object.assign({}, { background: 'yellow', opacity: 0.4,}, props.getCssProperties(area, props.rotation) )}
      />
      ))}
    </div>
    );

    const highlightPluginInstance = highlightPlugin({
      renderHighlights,
      trigger: Trigger.None,
    });


  /*To Prevent right click on screen*/
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });

  /*When document gets loaded successfully*/
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  // }


    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      setNumPages(numPages);
    }

    function changePage(offset) {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
      changePage(-1);
    }

    function nextPage() {
      changePage(1);
    }


    if(load){ 
      return(<div></div>) 
    }

    return (
    <>
    <div className="content">
    <Row  style={{ paddingLeft:"15px", paddingRight:"15px"}}>
    <ResizablePanels
    displayDirection="row"
    width="100%"
    height="100vh"
    panelsSize={[60,40]}
    sizeUnitMeasure="%"
    resizerColor="#00000024"
    resizerSize="10px"
    >
    <div>
    
    <Card style={{ marginBottom: "4px"}}>
    <div style={{display:"inline-block"}}>
    <a href='/admin/order' style={{marginLeft: "8px",float: "left",padding:"8px 0px",fontWeight:"500",textDecoration: "none"}}> {"<"} <span style={{textDecoration: "none"}}>Go to Order</span></a>
    <Button color="success" style={{width: "75px",marginRight: "0px",float: "right",alignSelf: "flex-end",padding:"6px 0px"}} onClick={()=>{SaveOrderDetail()}}>Save</Button>
    </div>
    </Card>

    <Card>
    <CardHeader>
    <h4 className="title">Property <span style={{float:"right"}} >SP ID: {data.property?data.softProOrderID:''}</span></h4>

    </CardHeader>
    <div className='bg-gra-plus' style={{ margin: "0px 15px 0px"}}> </div>
    <div className="border-new">
    <CardBody>
    <Form>
    <FormGroup>
    <Row className="py-1">
    <Col className="pr-md-1" md="2">
    <label>Address:</label>
    </Col>
    <Col className="px-md-1" md="9">
    <Input
    className="red-color"
    defaultValue=""
    placeholder="Propertyaddress"
    type="text"
    value={data.property && data.property.address && data.property.address !=null?data.property.address.address:''}
    onChange={(e)=>(ModifyOrderDetail('address',e.target.value,'property','address'))}
    />
    </Col>
    </Row>
    <Row className="py-1">
    <Col className="pr-md-1" md="2">
    <label>City:</label>
    </Col>
    <Col className="px-md-1" md="3">
    <Input
    className="red-color"
    defaultValue=""
    placeholder="City"
    type="text"
    value={data.property && data.property.address && data.property.address !=null?data.property.address.city:''}
    onChange={(e)=>(ModifyOrderDetail('city',e.target.value,'property','address'))}
    />
    </Col>
    <Col className="pr-md-1" md="1">
    <label>State:</label>
    </Col>
    <Col className="px-md-1" md="2">

    <Input type="select" name="select" id="exampleSelect" value={data.property && data.property.address && data.property.address !=null?data.property.address.state:''} style={{padding:"0 10px"}} onChange={(e)=>(ModifyOrderDetail('state',e.target.value,'property','address'))}>
    <option value="AL">AL</option>
    <option value="AK">AK</option>
    <option value="AZ">AZ</option>
    <option value="AR">AR</option>
    <option value="CA">CA</option>
    <option value="CO">CO</option>
    <option value="CT">CT</option>
    <option value="DE">DE</option>
    <option value="FL">FL</option>
    <option value="GA">GA</option>
    <option value="HI">HI</option>
    <option value="ID">ID</option>
    <option value="IL">IL</option>
    <option value="IN">IN</option>
    <option value="IA">IA</option>
    <option value="KS">KS</option>
    <option value="KY">KY</option>
    <option value="LA">LA</option>
    <option value="ME">ME</option>
    <option value="MD">MD</option>
    <option value="MA">MA</option>
    <option value="MI">MI</option>
    <option value="MN">MN</option>
    <option value="MS">MS</option>
    <option value="MO">MO</option>
    <option value="MT">MT</option>
    <option value="NE">NE</option>
    <option value="NV">NV</option>
    <option value="NH">NH</option>
    <option value="NJ">NJ</option>
    <option value="NM">NM</option>
    <option value="NY">NY</option>
    <option value="NC">NC</option>
    <option value="ND">ND</option>
    <option value="OH">OH</option>
    <option value="OK">OK</option>
    <option value="OR">OR</option>
    <option value="PA">PA</option>
    <option value="RI">RI</option>
    <option value="SC">SC</option>
    <option value="SD">SD</option>
    <option value="TN">TN</option>
    <option value="TX">TX</option>
    <option value="UT">UT</option>
    <option value="VT">VT</option>
    <option value="VA">VA</option>
    <option value="WA">WA</option>
    <option value="WV">WV</option>
    <option value="WI">WI</option>
    <option value="WY">WY</option>
    </Input>


    </Col>
    <Col className="pr-md-1" md="1">
    <label style={{textDecoration:"underline", color:'#4154f1'}}>Zip:</label>
    </Col>
    <Col className="pr-md-1" md="2">

    <Input className="red-color" placeholder="Zipcode" type="text" value={data.property && data.property.address && data.property.address !=null?data.property.address.postalCode:''} onChange={ (e)=>{

      ModifyOrderDetail('postalCode',e.target.value,'property','address')
      if(e.target.value.length == 5 || e.target.value.length == 9 ){
        setzipError(false);
      }else{
        setzipError(true);
      }
    }
  }/>
  { zipError && <span style={{fontSize:"12px",color:"red",position:"fixed"}} >please enter valid zipcode</span>}





  </Col>

  </Row>
  </FormGroup>

  </Form>
  </CardBody>

  </div>
  </Card>
  </div>

  <div className="main">
  {url != '' && <div style={{"display":"flex","justifyContent":"space-between"}}>

  <div className="pagec" hidden>
  Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
  </div>

  <div className="selectPDF">
  <Input style={{"padding":"0px 10px","height": "24px"}} type="select" name="select" id="exampleSelect" className="red-color" value={url}
  onChange={(event)=>{
    console.log(event.target.value)
    seturl(event.target.value)
  }}>
  {data.pdfurL1 && <option value={API.BASE_DOMAIN_PDFURL+''+data.pdfurL1}>PDF 1</option>}
  {data.pdfurL2 && <option value={API.BASE_DOMAIN_PDFURL+''+data.pdfurL2}>PDF 2</option>}
  </Input>
  </div>

  <div className="buttonc" hidden>
  <button style={{"fontSize":"smaller","background": "#fff","marginRight":"5px"}} type="button" disabled={pageNumber <= 1} onClick={previousPage} className="Pre">Previous</button>
  <button style={{"fontSize":"smaller","background": "#fff"}} type="button" disabled={pageNumber >= numPages} onClick={nextPage}>Next</button>
  </div>
  </div>
}

{/*<Document
file={url}
onLoadSuccess={onDocumentLoadSuccess}
>
<Page pageNumber={pageNumber} />
</Document>
*/}

{/*<div>
<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
<Page pageNumber={pageNumber} />
</Document>
<p>
Page {pageNumber} of {numPages}
</p>
</div>*/}

{
  url!='' && <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
  <div
  style={{
    height: "750px",
    maxWidth: "900px",
    marginLeft: "auto",
    marginRight: "auto"
  }}
  >
  <Viewer
  fileUrl={url}
  plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
  />
  </div>
  </Worker>
}


</div>
</ResizablePanels>
</Row>
</div>
</>
);
}

export default Property;
