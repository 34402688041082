/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import Dashboard from "views/Dashboard.js";
import OrderInformatation from "views/OrderInformatation.js";
import Property from "views/Property.js";
// import Icons from "views/Icons.js";
import OrderContacts from "views/OrderContacts.js";
import Notifications from "views/Notifications.js";
import TableList from "views/TableList.js";
import Order from "views/Order.js";
import Abstractor from "views/abstractor/Abstractor.js";
import User from "views/users/User.js";
import Document from "views/Document.js";
import Activity from "views/Activity.js";


// import Typography from "views/Typography.js";
// import UserProfile from "views/UserProfile.js";

export const routes = [

{
  path: "/orderinformatation",
  name: "Order Information",
  icon: "tim-icons icon-paper",
  component: OrderInformatation,
  layout: "/admin"
},
{
  path: "/property",
  name: "Property",
  icon: "tim-icons icon-world",
  component: Property,
  layout: "/admin"
},
{
  path: "/ordercontacts",
  name: "Order Contacts",
  icon: "tim-icons icon-single-02",
  component:OrderContacts,
  layout: "/admin"
},
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "tim-icons icon-bell-55",
  //   component: Notifications,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "tim-icons icon-single-02",
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "tim-icons icon-puzzle-10",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "tim-icons icon-align-center",
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/rtl-support",
  //   name: "RTL Support",
  //   icon: "tim-icons icon-world",
  //   component: Rtl,
  //   layout: "/rtl"
  // }
];

export const h_routes = [
{
  path: "/order",
  name: "Order",
  icon: "tim-icons icon-single-02",
  component:Order,
  layout: "/admin"
},
{
  path: "/abstractor",
  name: "Abstractor",
  icon: "tim-icons icon-single-02",
  component:Abstractor,
  layout: "/admin"
},
{
  path: "/users",
  name: "Users",
  icon: "tim-icons icon-single-02",
  component:User,
  layout: "/admin"
},
{
  path: "/document",
  //name: "Document",
  //icon: "tim-icons icon-single-copy-04",
  component:Document,
  layout: "/admin"
},
{
  path: "/activity",
  //name: "Activity",
  //icon: "tim-icons icon-puzzle-10",
  component:Activity,
  layout: "/admin"
}


];
