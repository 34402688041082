import React, { useState, useEffect, useRef,useContext } from 'react'
import { GET, POST, PUT, DELETE } from '../Services'
import API from '../Service/Api'
// import { Document, Page, pdfjs } from 'react-pdf';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ResizablePanels from '../components/ResizePanel';
import { OrderContext } from "../contexts/orderContext";
// import moment from 'moment'

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import type { HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";


function OrderInformatation(props) {

  const { orderInfo, ModifyOrderDetail,SaveOrderDetail} = useContext(OrderContext);
  console.log("#######",orderInfo);

  const [data, setdata] = useState({})
const [load, setload] = useState(true)

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const [numPages, setNumPages] = useState(null);
const [pageNumber, setPageNumber] = useState(1);



const [url, seturl] = useState('');
const defaultLayoutPluginInstance = defaultLayoutPlugin();

const areas=[
{
  pageIndex: 1,
  height: 8.55401,
  width: 50.1674,
  left: 27.5399,
  top: 15.0772,
}
]

useEffect(() => {
  getsdata() 
}, [orderInfo])


const handleAskPassword = (e: DocumentAskPasswordEvent) => {
  e.verifyPassword('&&89flowersDESCRIBE99!!');
};


const getBasicAuthenticationHeader = (username, password)=>{
  var auth = btoa(username + ":" + password);
  return "Basic " + auth;
}


const getsdata = async () => {

  console.log("orderInfo....",orderInfo)

  if(orderInfo && orderInfo != undefined){
    setdata(orderInfo)
    let tempURL = API.downloadFile+"?filename="+orderInfo.pdfurL1+"&type=json"
    var response = await GET(tempURL).catch((e) => {console.log("Get ERR", e) })
    if(response != null && response.status == "200" )
    {
      let purl =  process.env.REACT_APP_BASE_DOMAIN_PDFURL+''+orderInfo.pdfurL1
      seturl(purl)
    }
    setload(false)
  }
}




const renderHighlights = (props:RenderHighlightsProps) => (
  <div>
  {areas
  .filter((area) => area.pageIndex === props.pageIndex)
  .map((area, idx) => (
    <div
    key={idx}
    className="highlight-area"
    style={Object.assign({}, { background: 'yellow', opacity: 0.4,}, props.getCssProperties(area, props.rotation) )}
    />
    ))}
  </div>
  );

const highlightPluginInstance = highlightPlugin({
  renderHighlights,
  trigger: Trigger.None,
});


  /*To Prevent right click on screen*/
document.addEventListener("contextmenu", (event) => {
  event.preventDefault();
});

  /*When document gets loaded successfully*/
  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  //   setPageNumber(1);
  // }

function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
  setNumPages(numPages);
}


function changePage(offset) {
  setPageNumber(prevPageNumber => prevPageNumber + offset);
}

function previousPage() {
  changePage(-1);
}

function nextPage() {
  changePage(1);
}

if(load){ 
  return(<div></div>) 
}

return (
  <>
  <div className="content">
  <Row  style={{ paddingLeft:"15px", paddingRight:"15px"}}>
  <ResizablePanels
  displayDirection="row"
  width="100%"
  height="100vh"
  panelsSize={[60,40]}
  sizeUnitMeasure="%"
  resizerColor="#00000024"
  resizerSize="0px"

  >
  <div> 

  <Card style={{ marginBottom: "4px"}}>
  <div style={{display:"inline-block"}}>
  <a href='/admin/order' style={{marginLeft: "8px",float: "left",padding:"8px 0px",fontWeight:"500",textDecoration: "none"}}> {"<"} <span style={{textDecoration: "none"}}>Go to Order</span></a>
  <Button color="success" style={{width: "75px",marginRight: "0px",float: "right",alignSelf: "flex-end",padding:"6px 0px"}} onClick={()=>{SaveOrderDetail()}}>Save</Button>
  </div>
  </Card>

  <Card>
  <CardHeader>
  <h4 className="title">Order Information  <span style={{float:"right"}} >SP ID: {data.softProOrderID?data.softProOrderID:''}</span></h4>
  </CardHeader>
  <div className='bg-gra-plus' style={{ margin: "0px 15px 0px"}}> </div>
  <div className="border-new">
  <CardBody>
  <Form>
  <FormGroup>
  <Row className="py-1">
  <Col className="pr-md-1" md="2">
  <label>Product Name:</label>
  </Col>
  <Col className="px-md-1" md="9">
  <Input
  defaultValue="FC - TEST Full ALTA/JR ALTA/TITLE ONLY"
  placeholder="FC - TEST Full ALTA/JR ALTA/TITLE ONLY"
  type="text"
  onChange={(e)=>(ModifyOrderDetail('productName',e.target.value))}
  value={data.productName}
  />
  </Col>
  </Row>
  <Row className="py-1">
  <Col className="pr-md-1" md="2">
  <label>Ownership profile:</label>
  </Col>
  <Col className="px-md-1" md="9">
  <Input type="select" name="select" id="exampleSelect" value={data.ownerProfile}
  onChange={(e)=>(ModifyOrderDetail('ownerProfile',e.target.value))}>
  <option value="Default\PTE">Default\PTE</option>
  <option value="Default\PTE\Members-CA">Default\PTE\Members-CA</option>
  <option value="Default\PTE\PSG-TX">Default\PTE\PSG-TX</option>
  <option value="Default\PTE\PTA">Default\PTE\PTA</option>
  <option value="Default\PTE\PTE-FA">Default\PTE\PTE-FA</option>
  <option value="Default\PTE-Internal Files">Default\PTE-Internal Files</option>
  <option value="Closed">Closed</option>
  </Input>
  </Col>
  </Row>

  <Row className="py-1">
  <Col className="pr-md-1" md="2">
  <label>Transaction Type:</label>
  </Col>
  <Col className="px-md-1" md="3">
  <Input type="select" name="select" id="exampleSelect" value={data.transtype?data.transtype:''} onChange={(e)=>(ModifyOrderDetail('transtype',e.target.value))}>
  <option value="" disabled>Select</option>
  <option value="Purchase CD">Purchase CD</option>
  <option value="Purchase HUD">Purchase HUD</option>
  <option value="Refi CD">Refi CD</option>
  <option value="Refi HUD">Refi HUD</option>
  <option value="REO CD">REO CD</option>
  <option value="REO HUD">REO HUD</option>
  <option value="VA Fees">VA Fees</option>
  <option value="TX Capital Purchase CD">TX Capital Purchase CD</option>
  <option value="TX Capital Purchas  HUD">TX Capital Purchas  HUD</option>
  </Input>
  </Col>
  </Row>

  </FormGroup>

  </Form>

  </CardBody>


  <CardHeader>
  <h4 className="title" style={{fontSize:"1.1rem"}}>Status</h4>
  </CardHeader>
  <CardBody>
  <Form>
  <FormGroup>



  <Row className="py-1">
  <Col className="pr-md-1" md="2">
  <label>Order Status:</label>
  </Col>
  <Col className="px-md-1" md="3">
  <Input type="select" name="select" id="exampleSelect" className="red-color" value={data.orderStatus} onChange={(e)=>(ModifyOrderDetail('orderStatus',e.target.value))}>
  <option value="In process">In process</option>
  <option value="Hold">Hold</option>
  <option value="Completed">Completed</option>
  <option value="Canceled">Canceled</option>
  <option value="Closed">Closed</option>
  </Input>
  </Col>
  </Row>

  <Row className="py-1">
  <Col className="pr-md-1" md="2">
  <label>Purchase Side:</label>
  </Col>
  <Col className="px-md-1" md="3">
  <Input type="select" name="select" id="exampleSelect" value={data.purchaseSide?data.purchaseSide:''} onChange={(e)=>(ModifyOrderDetail('purchaseSide',e.target.value))}>
  <option value="" disabled>Select</option>
  <option value="Buyer">Buyer</option>
  <option value="Seller">Seller</option>
  <option value="Both">Both</option>
  </Input>
  </Col>

  </Row>



  <Row className="py-1">
  <Col className="pr-md-1" md="2">
  <label>Notes:</label>
  </Col>
  <Col className="px-md-1" md="10">

  <Input  disabled defaultValue=""
  placeholder="Enter the notes..."  rows="4"  type="textarea" style={{padding:"10px"}} onBlur={(e)=>{ ModifyOrderDetail('previousFileNote', e.target.value)}} />

  </Col>
  </Row>


  </FormGroup>

  </Form>
  </CardBody>
  <CardFooter>

  </CardFooter>
  </div>
  </Card>
  </div>  

  <div className="main" >
  {
    url != '' && <div style={{"display":"flex","justifyContent":"space-between"}}>

    <div className="pagec" hidden>
    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
    </div>

    <div>
    <Input style={{"padding":"0px 10px","height": "24px",marginBottom:"5px",fontWeight:"700"}} type="select" name="select" id="exampleSelect" className="red-color" value={url}
    onChange={(event)=>{
      console.log(event.target.value)
      seturl(event.target.value)
    }}>

    {data.pdfurL1 && <option value={API.BASE_DOMAIN_PDFURL+''+data.pdfurL1}>PDF 1</option>}
    {data.pdfurL2 && <option value={API.BASE_DOMAIN_PDFURL+''+data.pdfurL2}>PDF 2</option>}

    </Input>
    </div>

    <div className="buttonc" hidden>
    <button style={{"fontSize":"smaller","background": "#fff","marginRight":"5px"}} type="button" disabled={pageNumber <= 1} onClick={previousPage} className="Pre">Previous</button>
    <button style={{"fontSize":"smaller","background": "#fff"}} type="button" disabled={pageNumber >= numPages} onClick={nextPage}>Next</button>
    </div>

    </div>
  }

{/*<Document
file={url}
onLoadSuccess={onDocumentLoadSuccess}
>
{Array.from(new Array(numPages), (el, index) => (
  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
  ))}
</Document>
*/}

{/*  <div>
  <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
  <Page pageNumber={pageNumber} />
  </Document>
  <p>
  Page {pageNumber} of {numPages}
  </p>
  </div>
*/}




  {
    url!='' && <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">

    <div
    style={{
      height: "750px",
      maxWidth: "900px",
      marginLeft: "auto",
      marginRight: "auto"
    }}
    >
    <Viewer
    fileUrl={url} 
    plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
    />
    </div>
    </Worker>
  }


  </div>
  </ResizablePanels>
  </Row>             
  </div>
  </>
  );
}

export default OrderInformatation;
