import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function Login() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");

  const handleValidation = (event) => {
    let formIsValid = true;
console.log("email is",email);	
    if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      formIsValid = false;
      setemailError("Email Not Valid");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    if (!password.match(/^[0-9a-zA-Z]{8,22}$/)) {
      formIsValid = false;
      setpasswordError(
        "Only Letters and length must best min 8 Chracters and Max 22 Chracters"
        );
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }
	
    if (email != "alex.marr@prioritytitleus.com") {
      formIsValid = false;
      setemailError("Please enter correct email");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    if (password != "alexMarr2023") {
      formIsValid = false;
      setpasswordError("Please enter correct password");
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }
    return formIsValid;
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    if(handleValidation()){
      let udata = {email:email,isLogin:true}
      localStorage.setItem("userData", JSON.stringify(udata))
      console.log("dfdfdfd")
      window.location.href="/admin/order"
    }
  };

  return (
    <div className="container">
    <div className="row justify-content-center" >
    <div className="col-md-4" style={{border:'1px solid #ddd',position: "absolute",marginTop: "5%",padding:"35px 35px",width:"30rem"}}>
    <form id="loginform" onSubmit={loginSubmit}>
    <h3 className="text-center">Login</h3>
    <div className="form-group">
    <label>Email address</label>
    <input
    type="email"
    className="form-control"
    id="EmailInput"
    name="EmailInput"
    aria-describedby="emailHelp"
    placeholder="Enter email"
    onChange={(event) => setEmail(event.target.value)}
    />
    <small id="emailHelp" className="text-danger form-text">
    {emailError}
    </small>
    </div>
    <div className="form-group">
    <label>Password</label>
    <input
    type="password"
    className="form-control"
    id="exampleInputPassword1"
    placeholder="Password"
    onChange={(event) => setPassword(event.target.value)}
    />
    <small id="passworderror" className="text-danger form-text">
    {passwordError}
    </small>
    </div>

    <div className="text-center">
    <button type="submit" className="btn btn-info mt-4" style={{padding:"8px 35px"}}>Sign In</button>
    </div>

    </form>
    </div>
    </div>
    </div>
    );
}
export default Login;
