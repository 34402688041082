import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button,CardFooter,
CardText,
FormGroup,
Form,
Input,
Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from 'react-select';
import { Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import countryimg from '../../assets/img/country.png';
import abstractimg from '../../assets/img/abstract.png';
import flagimg from '../../assets/img/flag.png';
import dislikeimg from '../../assets/img/dislike.png';

import Userpop from './Userpop';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {GET,POST,PUT,DELETE} from '../../Services';
import dataHandler from '../../dataHandler';
import API from '../../Service/Api';


const Role = [{'value':'','label':'Select'},{'value':'Escrow Pod/Closer','label':'Escrow Pod/Closer'},{'value':'Pre-Closer/Escrow Assistant','label':'Pre-Closer/Escrow Assistant'},{'value':'Marketing Rep 1','label':'Marketing Rep 1'},{'value':'Marketing Rep 2','label':'Marketing Rep 2'} ]

const { SearchBar } = Search;


function User() {

  const [userModal, setUserModal] = React.useState({show:false,data:[],selected:null});
  const [deleteModal, setDeleteModal] = React.useState({show:false,data:null});
  
  const [data, setData] = React.useState([]);
  const paramRef = useRef({search:'',sortby:'first_name',sortin:1,page:1,limit:10,totalSize:0});

  const toastId = useRef(null);

  const [UnderWriter,setUnderWriter] = useState([])
  const [Escrow,setEscrow] = useState([])

  useEffect(()=>{
    if(dataHandler.getToken() == '' || dataHandler.getToken() == null){
      authentications()
    }
  },[])
  
  useEffect(() => {  
    getDropdowns();
  },[]);

   const getDropdowns = async () => {
    if(UnderWriter.length == 0){
  
      let underwriterURL = API.DropdownServies+"?contact=Underwriter&type=json"
      var response2 = await GET(underwriterURL).catch((e) => { console.log("Get ERR", e) })
      if(response2 != null && response2.status == 200)
      {
        let result1 = response2.data.map(item=>({value:item.lookupcode,label:item.name}))
        result1.unshift( {'value':'','label':'Select'} );
        setUnderWriter(result1)
      }

      let escrowURL = API.DropdownServies+"?contact=MarketingRep&type=json"
      var response3 = await GET(escrowURL).catch((e) => { console.log("Get ERR", e) })
      if(response3 != null && response3.status == 200)
      {
        let result1 = response3.data.map(item=>({value:item.lookupcode,label:item.name}))
        result1.unshift( {'value':'','label':'Select'} );
        setEscrow(result1)
      }      
    } 
  }
  

  //State dropdowns. 
  const authentications = async()=>{
    console.log("authentication triggered");
    
    if(toastId.current === null){
      toastId.current = toast.loading('Authenticating');
    }

    var tempJSON = JSON.stringify({email:process.env.REACT_APP_BASE_DOMAIN_EMAIL,password:process.env.REACT_APP_BASE_DOMAIN_RAWPASSWORD})

    let tempURL = API.Auth;
    var response = await POST(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })
    console.log("response ...",response);

    if(response.code == 200)
    {
      toast.update(toastId.current,{rendor:"Successfully saved", type:"success",isLoading:false,autoClose:5000,})
      toast.done(toastId.current)
      dataHandler.setToken(response.token)
      Loaddata();
    }else{
      toast.update(toastId.current,{rendor:"Please fill all the fields", type:"error", autoClose:5000, isLoading:false})
      toast.done(toastId.current)
    }
  }


  useEffect(()=>{
    if(dataHandler.getToken() != ''){
      Loaddata();
    }
  },[])

  const Loaddata = async()=>{
    getdata()
  }


  function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
  }

  const getdata = async()=>{
    var param = objectToQueryString(paramRef.current);
    var url=API.User+"?"+param+"&token="+dataHandler.getToken()
    var response = await GET(url).catch((e) => console.log("Fetch Error", e))

    if (response != null && response.code != null && response.code == 200) {

      paramRef.current.page = response.metadata.page?response.metadata.page:1
      paramRef.current.totalSize = response.metadata.total?response.metadata.total:0

      console.log(response);
      setData(response.data)
    }
  }


  const getClientUsers = async(row)=>{
    console.log(row);

    var url=API.userClient+"/"+row.client_name+"?token="+dataHandler.getToken()
    var response = await GET(url).catch((e) => console.log("Fetch Error", e))

    if (response != null && response.code != null && response.code == 200) {
      
      console.log("sdsds",response.data)
      
      setUserModal(prevState => ({ ...prevState, show:true,data:response.data,selected:row}))
      // setData(response.data)
    }
  }


  const searchAbstractor = (e) =>{
    paramRef.current.search = e.target.value; 
    getdata();
  };

  const deleteAbstractr = async(e) =>{

    console.log(deleteModal.data);

    if(deleteModal.data != null){

      if(toastId.current === null){
        toastId.current = toast.loading('delete in progress');
      }

      let tempURL = API.User+'/'+deleteModal.data._id+"?token="+dataHandler.getToken()
      var response = await DELETE(tempURL).catch((e) => { console.log("Get ERR", e) })
      console.log("response ...",response);

      if(response != null)
      {
        toast.update(toastId.current,{rendor:"Successfully deleted", type:"success",isLoading:false,autoClose:5000,})
        toast.done(toastId.current)
        setDeleteModal(prevState => ({ ...prevState, show:false,data:null}))
        getdata()

      }else{
        toast.update(toastId.current,{rendor:"Something went wrong", type:"error", autoClose:5000, isLoading:false})
        toast.done(toastId.current)
      }

    }else{
      setDeleteModal(prevState => ({ ...prevState, show:false,data:null}))
    }

  };  



  const handleTableChange = (e,data) =>{
    paramRef.current.page = data.page
    getdata()
  };



  const columns = [ 
  {
    dataField: "client_name",
    text: "Client Name",
    sort: true,
    headerClasses: 'th-first1',
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "name",
    text: "Emp. Name",
    sort: true,
    headerClasses: 'th-first1',
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
    {
    dataField: "email",
    text: "Emp. Email",
    sort: true,
    headerClasses: 'th-first1',
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "escrow_pod",
    text: "Escrow pod/Closer",
    sort: true,
    headerClasses: 'th-first1',
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "escrow_assistant",
    text: "Escrow Assistant",
    sort: true,
    headerClasses: 'th-first1',
    onSort: (field, order) => {
    paramRef.current.sortby=field
    paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "marketing_rep1",
    text: "Marketing Rep 1",
    sort: true,
    headerClasses: 'th-first1',
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "marketing_rep2",
    text: "Marketing Rep 2",
    sort: true,
    headerClasses: 'th-first1',
    onSort: (field, order) => {
      paramRef.current.sortby=field
      paramRef.current.sortin= (order=='asc')? 1:-1;
    }
  },
  {
    dataField: "Action",
    text: "Action",
    headerClasses: 'th-last1',
    formatter: (cellContent, row) => (
      <div className="">
      <Button color="success" style={{fontSize:"12px",padding:"1px 12px",margin:"0px 5px"}} onClick={()=>{getClientUsers(row);}} >Edit</Button>
      
      <Button color="danger" style={{fontSize:"12px",padding:"1px 12px",margin:"0px 5px"}} onClick={()=>{setDeleteModal(prevState => ({ ...prevState, show:true,data:row}))}}  disabled={row.name != 'Default All'?false:true} >Delete</Button>
      
      </div>
      )
  }
  ];

  return ( 
    <div className="content">
    <Row>
    <Col md="12">
    <Card>
    <CardHeader>
    <CardTitle><h4 className="title">Users</h4></CardTitle>
    </CardHeader>
    <CardBody>
    <div className='bg-gra-plus'>
    <Button  className="btn-plus" onClick={()=>{setUserModal(prevState => ({ ...prevState, show:true,data:null }))}} >+</Button>
    </div>
    
    <div className="search-filter">
    <label></label>
    <div className="filterpart-abs">
    <Input placeholder="Search" type="text" className="search-input"  onChange={searchAbstractor}/>
    </div>
    </div>

    <ToolkitProvider
    bootstrap4
    keyField="orderID"
    data={ data }
    columns={ columns }
    search={ true }
    >
    { 
      props => (
        <div className='search-select-bar seach-select-bar-abstractor'>

        <BootstrapTable
        classes="Home-table abstractortable"
        { ...props.baseProps }
        remote
        pagination={paginationFactory({ page:paramRef.current.page, sizePerPage: paramRef.current.limit,sizePerPageList:[],totalSize:paramRef.current.totalSize,paginationSize:10})}
        striped
        hover
        condensed
        onTableChange={handleTableChange}
        />
        </div>  
        )
    }
    </ToolkitProvider>
    </CardBody>
    </Card>
    </Col>
    </Row>

    <Modal className="addorder" isOpen={deleteModal.show} size="md" >
    <ModalHeader></ModalHeader>
    <ModalBody  style={{paddingTop:"15px"}}>
    <div className="content">
    <Card style={{border:"0",marginBottom:"0px"}}>

    <CardBody>
    <Row>

    <Col md="12" className="text-center">
    <div className="h5 mb-4">
    <span>Are you sure, do you want to delete <span style={{color:"red"}}>"{deleteModal.data?.name }"</span> Employee?</span>
    </div>
    </Col>
    
    <Col md="12" className="text-center">
    <Button  color="danger" style={{marginTop:"10px",padding:"6px 10px"}} onClick={deleteAbstractr} >Yes,Delete It!</Button>
    <Button  color="info" style={{marginTop:"10px",padding:"6px 10px"}}  onClick={()=>{setDeleteModal(prevState => ({ ...prevState, show:false,data:null}))}}>No</Button>
    </Col>

    </Row>

    </CardBody>
    </Card>
    </div>
    </ModalBody>
    </Modal>


    <Userpop key={userModal.show} show={userModal.show} selected={userModal.selected} data={userModal.data} setUserModal={setUserModal} Escrow={Escrow} getdata={getdata}/>

    </div>
    );
}

export default User;