import React, { useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, CardTitle, Table, Row, Col, Button,CardFooter,
CardText,
FormGroup,
Form,
Input,
Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {GET,POST,DELETE} from '../Services';
import API from '../Service/Api';
import Trash from '../assets/img/trash.png';
import Refresh from '../assets/img/refresh.png';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from "react-js-loader";

const { SearchBar } = Search;

function Activity(props) {

  const [loader, setLoader] = React.useState(false);
  const [activities, setActivities] = useState([])

  const columns = [
  {
    dataField: "createdBy",
    text: "CreatedBy",
    sort: true
  },
  {
    dataField: "createdOn",
    text: "CreatedOn",
    sort: true
  },
  {
    dataField: "abstractor",
    text: "Abstractor",
    sort: true
  },
  {
    dataField: "escrowOfficer",
    text: "Escrow",
    sort: true
  },
  {
    dataField: "escrowAssistant",
    text: "escrow Assistant",
    sort: true
  },
  {
    dataField: "orderNumber",
    text: "OrderId",
    sort: true,
  },
  {
    dataField: "purchaseSide",
    text: "PurchaseSide",
    sort: true
  },
  {
    dataField: "transactionType",
    text: "Trans. Type",
    sort: true
  },
  {
    dataField: "underwritter",
    text: "UnderWriter",
    sort: true
  },
  {
    dataField: "Action",
    text: "Status",
    headerClasses: 'th-last',
    formatter: (cellContent, row) => {

      if(row.precentage && row.precentage>0){
        return(
          <div className="">
          <div className="progress">
          <div className="progress-bar bg-success" role="progressbar" aria-valuenow={row.precentage} aria-valuemin="0" aria-valuemax="100" style={{width:row.precentage+"%"}}>{row.precentage}%</div></div>
          </div>
          )
      }else{
        return(
          <div className="">
          <div className="progress">
          <div className="progress-bar bg-danger" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width:"100%"}}>FAILED</div></div>
          </div>
          )
      }

    }
  },
  {
    dataField: "message",
    text: "Comment",
    sort: false
  }

  ];


  useEffect(() => {  getList(); }, [props]);

  const getList = async () => {

    let tempURL = API.activity+"?ocrtype=2&type=json"
    var response = await GET(tempURL).catch((e) => { console.log("Get ERR", e) })
    console.log(response);
    if(response != null && response.status == "SUCCESS" )
    {
      console.log("orders",response)
      setActivities(response.orders)
      setLoader(false)
    }
  }


  return (
    <div className="content">
    <Row>
    <Col md="12">
    <Card>
    <CardHeader>
    <CardTitle><h4 className="title">Activity</h4></CardTitle>
    </CardHeader>
    <CardBody>
    <div className='bg-gra-plus'>
    <Button  className="btn-plus" onClick={()=>{getList(); setLoader(true)}} style={{float: 'right', top: "-2px"}}><img src={Refresh} style={{width:"30px"}}/></Button>
    { 
      loader && 
      <div style={{position: "absolute",background: "#d8f0ff5c",width: "100%",height: "100vh",zIndex:1}} >
      <div style={{position: "absolute",top: "45vh",bottom: "auto",left: "50%",zIndex:1}}>
      <Loader type="spinner-cub" bgColor="#2a879e" color="#2a879e" size={50}/>
      </div>
      </div>
    }

    </div>

    <ToolkitProvider
    bootstrap4
    keyField="orderID"
    data={ activities }
    columns={ columns }
    search={ true }
    >
    {
      props => (
        <div className='search-select-bar'>
        <SearchBar { ...props.searchProps } />

        <BootstrapTable
        classes="Home-table"
        { ...props.baseProps }
        pagination={paginationFactory({ sizePerPage: 25 })}
        striped
        hover
        condensed
        />
        </div>
        )
    }
    </ToolkitProvider>

    </CardBody>
    </Card>
    </Col>
    </Row>


    </div>
    );
}

export default Activity;