/*!

=========================================================
* Black Dashboard React v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from 'react'
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import {routes,h_routes} from "routes.js";

import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

import { OrderContext } from "contexts/orderContext";

import {GET,POST} from '../../Services'
import API from '../../Service/Api'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var ps;

function Admin(props) {

  const location = useLocation();
  const tempUrlParams = new URLSearchParams(location.search);
  // const orderId = tempUrlParams.get('id') !=null? tempUrlParams.get('id') : 757;
  const orderId = tempUrlParams.get('id') !=null? tempUrlParams.get('id') : null;

  const toastId = useRef(null);

  const mainPanelRef = useRef(null);

  const orderIdRef = useRef(orderId);

  const [orderdetail, setorderdetail] = useState()

  const [sidebarOpened, setsidebarOpened] = useState(
    document.documentElement.className.indexOf("nav-open") !== -1
    );



  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);

  useEffect(() => {  getList(); }, []);

  const getList = async () => {

   if(orderIdRef.current == null) return false;
  
      let tempURL = API.aorder+"/"+orderIdRef.current+"?type=json"

      var response = await GET(tempURL).catch((e) => { console.log("Get ERR", e) })
        console.log("adfadf",typeof response);
      if(response != null && response.Message != undefined)
      {
   window.location = "/admin/order"
       
      }else{
        setorderdetail(response[0])
       // toast.error(response.Message);
    }

  //  const fff = [{"orderID":1,"softProOrderID":"756","orderType":"NEW","vendorOrderID":"","vendorUniqueID":null,"productID":"0","productName":"","vendorID":"0","vendorType":"","orderStatus":null,"titleStatus":"","approved":0,"createdBy":"","ownerProfile":"","createdDate":"0001-01-01T00:00:00","updatedDate":"0001-01-01T00:00:00","property":{"orderID":0,"addressID":0,"loanNumber":"145654654","loanAmount":"","reoNumber":"123456","status":null,"address":{"city":"ca","postalCode":"7500","state":"los vegas","street1":"12","street2":"as","country":"usa","unitnumber":"1"}},"contacts":[{"contactid":1,"orderid":1,"addressid":2,"type":"lender","firstname":"john","lastname":"doe","email":"john@yopmail.com","fax":"45645","primaryphone":"7845612","code":null,"address":{"city":"la","postalcode":"c545a","state":"california","street1":"11","street2":"staaring","country":"usa","unitnumber":"1"}},{"contactid":2,"orderid":1,"addressid":3,"type":"borrower","firstname":"als","lastname":"aa","email":"als","fax":"456","primaryPhone":"1321","code":null,"address":{"city":"ca","postalCode":"zs","state":"test","street1":"dd","street2":"dd","country":"usa","unitNumber":"132"}}],"attachments":null}];
   // setorderdetail(fff[0])

  }


  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        var pp = prop.layout + prop.path
        return ( <Route path={pp} component={prop.component} key={key} /> );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    console.log("path",path)
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }

    }
    for (let i = 0; i < h_routes.length; i++) {
    if (location.pathname.indexOf(h_routes[i].layout + h_routes[i].path) !== -1) {
      return h_routes[i].name;
    }
  }
  };


  const ModifyOrderDetail = (key,value,key1=null,key2=null) => {

    console.log("ModifyOrderDetail",key,value)
    if(key1 != null && key2 !=null){
      let temp = orderdetail[key1][key2];
      if(temp==null) 
		temp={"city":"","postalCode":"","state":"AL","street1":"","street2":"","country":"","unitNumber":"1"}
	  temp[key] = value 
	  
      let tmp = orderdetail[key1]
      tmp[key2] = temp 
      setorderdetail( prevState => ({...prevState,[key1]:tmp}))
    }else if(key1 !=null){
      let temp = orderdetail[key1];
      temp[key] = value 
      setorderdetail( prevState => ({...prevState,[key1]:temp}))
    }else{
      setorderdetail( prevState => ({...prevState,[key]:value}))
    }
	
  }

  const SaveOrderDetail = async () => {

    console.log("SaveOrderDetail",orderdetail)

    if(toastId.current === null){
      toastId.current = toast.loading('upload in progress');
    }else{
      toast.update(toastId.current)
    }

    var tempJSON = JSON.stringify(orderdetail)

    let tempURL = API.updateUiOrder
    // let tempURL = API.updateOrder

    var response = await POST(tempURL, tempJSON).catch((e) => { console.log("Get ERR", e) })

    console.log("response ...",response);

    if(response != null && response != undefined)
    {
     toast.update(toastId.current,{rendor:"Successfully saved!", type:"success",isLoading:false})
     toast.done(toastId.current)
    }else{
     toast.update(toastId.current,{rendor:"Something went wrong.", type:"error",isLoading:false})
     toast.done(toastId.current)
   }

 }



 return (
  <OrderContext.Provider value = {{orderInfo :orderdetail, ModifyOrderDetail,SaveOrderDetail}}>
  <BackgroundColorContext.Consumer>

  {({ color, changeColor }) => (
    <React.Fragment>
    <div className="wrapper">


    { 
      orderIdRef.current !=null &&
      <Sidebar
      routes={routes}
      logo={{
        text: "General",
        imgSrc: logo
      }}
      toggleSidebar={toggleSidebar}
      />
    }
    { 
      orderIdRef.current == null &&
      <Sidebar
      routes={h_routes}
      logo={{
        text: "General",
        imgSrc: logo
      }}
      toggleSidebar={toggleSidebar}
      />
    }


    <div className="main-panel" ref={mainPanelRef} data={color}>

    <AdminNavbar
    brandText={getBrandText(location.pathname)}
    toggleSidebar={toggleSidebar}
    sidebarOpened={sidebarOpened}
    />

    { 
      orderIdRef.current !=null &&
      <Switch>
      {getRoutes(routes)}
      </Switch>
    }
    { 
      orderIdRef.current == null &&
      <Switch>
      {getRoutes(h_routes)}
      </Switch>
    }

    </div>
    </div>
    <FixedPlugin bgColor={color} handleBgClick={changeColor} />
    </React.Fragment>
    )}

    </BackgroundColorContext.Consumer>

    <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    />
{/* Same as */}
    <ToastContainer />

    </OrderContext.Provider>
    );
  }

  export default Admin;
